import { ACTIVE_LOAN } from './routes';
import { Navigate } from 'react-router-dom';
import { ReactElement } from 'react';

type Props = {
  children: ReactElement;
};

export default function PublicRoute({ children, ...props }: Props) {
  const userNotAuthorized = !sessionStorage.getItem('token') || window.location.search.includes('from2fa=true');
  if (userNotAuthorized) {
    return children;
  }

  return <Navigate to={ACTIVE_LOAN} replace />;
}
