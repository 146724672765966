import { ReactNode } from 'react';
import classes from 'classnames';
import styles from './Placeholder.module.scss';

export type Props = {
  children: ReactNode;
  dataTest?: string;
  placeholder?: string;
  loading?: boolean;
  /** Replace current children with placeholder */
  replace?: boolean;
  className?: string;
};

export default function Placeholder({
  children,
  loading,
  placeholder = 'Loading',
  dataTest,
  replace = false,
  className,
}: Props) {
  return (
    <span
      className={classes(loading && styles.wrapper, className)}
      data-test={dataTest}
    >
      {(children || children === 0) && (!replace || !loading)
        ? children
        : placeholder}
    </span>
  );
}
