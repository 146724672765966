import { memo, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classes from 'classnames';
import styles from './Link.module.scss';

export type Props = {
  to?: string;
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  external?: boolean;
  dataTest?: string;
  size?: 'm' | 's';
  onClick?: () => void;
  onMouseEnter?: () => void;
  loading?: boolean;
};

export default memo(function Link({
  to = '',
  children,
  disabled = false,
  className,
  external = false,
  dataTest,
  size = 'm',
  onClick,
  onMouseEnter,
  loading,
}: Props) {
  if (disabled || loading)
    return (
      <span
        onMouseEnter={onMouseEnter}
        data-test={dataTest}
        className={classes(
          styles.link,
          styles[size],
          disabled && styles.disabled,
          loading && styles.loading,
          className,
        )}
      >
        {children}
      </span>
    );

  if (onClick) {
    return (
      <button
        onMouseEnter={onMouseEnter}
        type="button"
        onClick={onClick}
        data-test={dataTest}
        className={classes(styles.link, styles[size], className)}
      >
        {children}
      </button>
    );
  }

  if (external)
    return (
      <a
        onMouseEnter={onMouseEnter}
        onClick={onClick}
        data-test={dataTest}
        className={classes(styles.link, styles[size], className)}
        href={to}
        rel="noopener"
      >
        {children}
      </a>
    );

  return (
    <RouterLink
      onMouseEnter={onMouseEnter}
      data-test={dataTest}
      className={classes(styles.link, styles[size], className)}
      to={to}
    >
      {children}
    </RouterLink>
  );
});
