import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import dayjs from 'dayjs';
import fetchData from '../../utils/fetchData';
import pMemoize from 'p-memoize';
import useError from '../useError';

const memoFetchData = pMemoize(fetchData, { maxAge: 1000 });

type Props = {
  children: ReactNode;
};

type TransactionType =
  | 'MAIN'
  | 'EXTENSION'
  | 'NEW_AMOUNT'
  | 'DEBT_COLLECTION'
  | 'PRINCIPAL_COLLECTION'
  | 'RESCHEDULED'
  | 'RENOUNCEMENT'
  | 'INTEREST_RATE_CHANGE'
  | 'MAIN_RESCH'
  | 'EXTENSION_RESCH'
  | 'NEW_AMOUNT_RESCH'
  | 'CANCELLED_DISCOUNTS'
  | 'RECALCULATED'
  | 'RECALCULATED_DELAYED'
  | 'CONTRACTUAL_RESCH';

export type LoanExtension = {
  term: number;
  feeWithoutInterest: number;
  interest: number;
  unit: 'DAYS' | 'MONTHS';
  fee: number;
  expirationDate?: string;
};
type Term = {
  termUnit: 'DAYS' | 'MONTHS';
  value: number;
};

export type LatestLoan = {
  payNowAmount: number;
  payableAmount: number;
  loanExtensionFees: Array<LoanExtension>;
  autoRepay: boolean;
  dueDate: string;
  isExtensionPossible: boolean;
  loanNumber: string;
  schedulePayment: number;
  totalOutstandingAmount: number;
  totalPrincipal: number;
  interestAmount: number;
  loanTerm: Term;
  mainAgreementTerm: Term;
  totalInvoicedAmount: number;
  principalAmount: number;
  nearestPaymentDate: string;
  penaltyAmount: number;
  overDueDays: number;
  productNumber: string; // TODO: add an enum to cover the precise product numbers
  openAmount: number;
  earlyFullRepaymentFee: number;
  transactionType: TransactionType;
  debtCollectLoanAgreement: boolean; // True when loan agreement has been broken
  agreementType: 'MAIN' | 'NEW_AMOUNT' | 'EXTENSION' | 'DEBT_COLLECTION';
  commissionAmount: number;
  gracePeriodInDays: number;
  amount: number;
  totalOutpaidAmount: number;
  // keys which we are adding on the FE, don't mix with values from the BE
  daysLeft: number;
  interestRatePerYear: number;
  interestRate: number;
  annualPercentageRate: number;
  hasActiveDebtSchedule: boolean;
  term: number;
};

type AutoRepayBodyType = {
  body: {
    autoRepay: boolean;
  };
};

type PaymentsSummary = {
  repaymentsDistributionsAmount: number;
};

export type LatestPayment = {
  amount: number;
  bookingDate: string;
};

type LatestLoanContextType = {
  latestLoan?: LatestLoan | null;
  selectedLoanExtension?: LoanExtension;
  fetchLatestLoan: () => Promise<LatestLoan | void | null>;
  fetchLatestLoanInvoice: () => Promise<void>;
  setSelectedLoanExtension: React.Dispatch<
    React.SetStateAction<LoanExtension | undefined>
  >;
  autoRepay: ({ body }: AutoRepayBodyType) => Promise<LatestLoan | void | null>;
  paymentsSummary?: PaymentsSummary;
  fetchPaymentsSummary: () => Promise<PaymentsSummary | void | null>;
  latestPayment?: LatestPayment;
  fetchLatestPayment: () => Promise<LatestPayment | void>;
};

const LatestLoanContext = createContext<LatestLoanContextType>(
  {} as LatestLoanContextType,
);

const useLatestLoan = () => useContext(LatestLoanContext);

const LatestLoanProvider = ({ children }: Props): JSX.Element => {
  const [latestLoan, setLatestLoan] = useState<LatestLoan | null>();
  const [selectedLoanExtension, setSelectedLoanExtension] =
    useState<LoanExtension>();
  const [paymentsSummary, setPaymentsSummary] = useState<PaymentsSummary>();
  const [latestPayment, setLatestPayment] = useState<LatestPayment>();

  const { showError } = useError();

  const fetchLatestLoan =
    useCallback(async (): Promise<LatestLoan | void | null> => {
      try {
        const fetchedLatestLoan: LatestLoan | undefined = await memoFetchData(
          '/client/loans/latest',
        );

        // checking `daysLeft` as we hardcode it in the fixtures for integration tests
        if (fetchedLatestLoan && !fetchedLatestLoan.daysLeft) {
          const today = dayjs().format('YYYY-MM-DD');

          fetchedLatestLoan.daysLeft = dayjs(fetchedLatestLoan.dueDate).diff(
            dayjs(today),
            'day',
          );
        }

        if (fetchedLatestLoan && fetchedLatestLoan.loanExtensionFees) {
          // Sort extensions from smallest to biggest
          fetchedLatestLoan.loanExtensionFees =
            fetchedLatestLoan.loanExtensionFees.sort((a, b) => {
              const firstElementTerm =
                a.unit === 'MONTHS' ? a.term * 31 : a.term;
              const secondElementTerm =
                b.unit === 'MONTHS' ? b.term * 31 : b.term;

              return firstElementTerm - secondElementTerm;
            });
        }

        setLatestLoan(fetchedLatestLoan);

        return fetchedLatestLoan;
      } catch (e) {
        // 404 is thrown if latestLoan doesn't exist. In this case we don't want to throw errors
        if (e.message === '404') {
          setLatestLoan(null);
          return null;
        }
        showError();
        throw e;
      }
    }, [showError]);

  const fetchLatestLoanInvoice = useCallback(async (): Promise<void> => {
    try {
      const fetchedLatestLoanInvoice = await memoFetchData(
        '/client/loans/latest/invoice',
        {
          headers: {
            Accept: 'application/pdf',
            'Content-Type': 'application/pdf',
          },
        },
      );

      const fileURL = URL.createObjectURL(fetchedLatestLoanInvoice);

      window.open(fileURL);
    } catch (e) {
      showError();
      throw e;
    }
  }, [showError]);

  const autoRepay = useCallback(
    async ({ body }: AutoRepayBodyType): Promise<LatestLoan | void | null> => {
      try {
        const data = await fetchData(`/client/loans/latest/auto-repay`, {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
        setLatestLoan(data);
      } catch (e) {
        if (e.name !== 'ValidationError') {
          showError();
          throw e;
        }
        // Don't throw error otherwise
      }
    },
    [showError],
  );

  const fetchPaymentsSummary =
    useCallback(async (): Promise<PaymentsSummary | void> => {
      try {
        const data = await fetchData(`/client/loans/latest/payments/summary`);
        setPaymentsSummary(data);
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const fetchLatestPayment =
    useCallback(async (): Promise<LatestPayment | void> => {
      try {
        const data = await fetchData(`/client/loans/latest/payments/latest`);
        setLatestPayment(data);
      } catch (e) {
        if (e.name === 'Error' && e.message === '404') {
          // Not found is valid state, don't show error notification
          return;
        }
        showError();
        throw e;
      }
    }, [showError]);

  const latestLoanContextValue = useMemo(
    () => ({
      latestLoan,
      selectedLoanExtension,
      fetchLatestLoan,
      setSelectedLoanExtension,
      fetchLatestLoanInvoice,
      autoRepay,
      fetchPaymentsSummary,
      paymentsSummary,
      fetchLatestPayment,
      latestPayment,
    }),
    [
      latestLoan,
      selectedLoanExtension,
      fetchLatestLoan,
      fetchLatestLoanInvoice,
      autoRepay,
      fetchPaymentsSummary,
      paymentsSummary,
      fetchLatestPayment,
      latestPayment,
    ],
  );

  return (
    <LatestLoanContext.Provider value={latestLoanContextValue}>
      {children}
    </LatestLoanContext.Provider>
  );
};

export { useLatestLoan as default, LatestLoanProvider };
