import * as R from '../../../../Router/routes';
import { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { MenuItem } from './MobileMenu';
import classes from 'classnames';
import Heading from '../../../Heading/Heading';
import Link from '../../../Link/Link';
import MobileMenu from './MobileMenu';
import Portal from '../../../Portal/Portal';
import styles from './MobileHeader.module.scss';
import useBreakpoint from '../../../../hooks/useBreakpoint';

import { AsyncActiveLoan } from '../../../../pages/ActiveLoan/async';
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron-sharp.svg';
import { LoadableComponentMethods } from '@loadable/component';
import { ReactComponent as LogoMini } from '../../../../assets/icons/logo-mini.svg';
import { ReactComponent as LogoSmall } from '../../../../assets/icons/logo-small.svg';
import { ReactComponent as Menu } from '../../../../assets/icons/menu.svg';

export type Props = {
  logoLinkUrl?: string;
  homePreload?: LoadableComponentMethods<unknown>['preload'];
  backUrl?: string;
  backPreload?: LoadableComponentMethods<unknown>['preload'];
  heading?: string | ReactNode;
  menuItems: MenuItem[];
  className?: string;
  onLogout?: () => void;
  clientName?: string;
  clientContract?: string;
  pending?: boolean;
  withMenu?: boolean;
};

export default memo(function MobileHeader({
  logoLinkUrl = R.ACTIVE_LOAN,
  homePreload = AsyncActiveLoan.preload,
  backUrl,
  backPreload,
  heading,
  menuItems,
  className,
  onLogout,
  clientName,
  clientContract,
  pending,
  withMenu = true,
}: Props) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { size } = useBreakpoint();

  useEffect(() => {
    if (size.index > 2) {
      setIsMobileMenuOpen(false);
    }
  }, [size.index]);

  useEffect(() => {
    backPreload?.();
    homePreload?.();
  }, [homePreload, backPreload]);

  const handleMenuToggle = useCallback(() => {
    setIsMobileMenuOpen((prevState) => !prevState);
  }, []);

  return (
    <>
      <header className={classes(styles.header, className)}>
        <div className={styles.item}>
          {backUrl ? (
            <Link
              to={backUrl}
              dataTest="mobile-back-link"
              className={styles.back}
            >
              <ChevronIcon className={styles.chevron} />
            </Link>
          ) : (
            withMenu && (
              <Link to={logoLinkUrl} dataTest="mobile-back-link">
                <LogoSmall
                  data-test="mobile-logo"
                  className={styles['logo-small']}
                />
              </Link>
            )
          )}
        </div>

        <div className={classes(styles.item, styles.center)}>
          {withMenu ? (
            <Heading className={classes(styles['mobile-heading'])}>
              {heading}
            </Heading>
          ) : (
            <Link to={logoLinkUrl}>
              <LogoMini data-test="mobile-logo" />
            </Link>
          )}
        </div>
        <div className={classes(styles.item, styles.right)}>
          {withMenu && (
            <div className={styles['menu-button']} onClick={handleMenuToggle}>
              <Menu />
            </div>
          )}
        </div>
      </header>

      {isMobileMenuOpen && (
        <Portal>
          <MobileMenu
            className={styles.menu}
            onSelect={handleMenuToggle}
            onClose={handleMenuToggle}
            menuItems={menuItems}
            active={window.location.pathname}
            clientName={clientName}
            clientContract={clientContract}
            onLogout={onLogout}
            pending={pending}
          />
        </Portal>
      )}
    </>
  );
});
