/** Layout for flow type pages */

import { LoadableComponentMethods } from '@loadable/component';
import {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuth } from '../../../hooks/webapi';
import classes from 'classnames';
import DesktopHeader from '../components/DesktopHeader/DesktopHeader';
import ErrorNotifications from '../ErrorNotifications/ErrorNotifications';
import Footer from '../components/Footer/Footer';
import MobileHeader from '../components/MobileHeader/MobileHeader';
import styles from './Flow.module.scss';
import SuccessNotifications from '../SuccessNotifications/SuccessNotifications';
import useMenuItems from '../../../hooks/useMenuItems';

export type Props = {
  children: ReactNode;
  backUrl?: string;
  backPreload?: LoadableComponentMethods<unknown>['preload'];
  withLogout?: boolean;
  mobileHeading?: string;
  className?: string;
  dataTest?: string;
  withMenu?: boolean;
  logoLinkUrl?: string;
  homePreload?: LoadableComponentMethods<unknown>['preload'];
  withFooter?: boolean;
  // NOTE: 'fullWidthBreakpoint' is used for when the layout components
  // needs to extend the full width of the viewport at certain breakpoints
  fullWidthBreakpoint?: 's';
};

export default memo(function Flow({
  children,
  backUrl,
  backPreload,
  withLogout,
  mobileHeading,
  className,
  dataTest,
  logoLinkUrl,
  homePreload,
  fullWidthBreakpoint,
  withMenu = true,
  withFooter = true,
}: Props) {
  const { logout } = useAuth();

  const menuItems = useMenuItems({ shouldFetchMenu: withMenu });
  const [pending, setPending] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fullWidthClass = useMemo(
    () =>
      fullWidthBreakpoint &&
      classes(styles['full-width'], styles[fullWidthBreakpoint]),
    [fullWidthBreakpoint],
  );

  const handleLogout = useCallback(async () => {
    try {
      setPending(true);
      await logout();
    } catch {
      setPending(false);
    }
  }, [logout]);

  return (
    <div className={styles.container} data-test={dataTest}>
      <div className={classes(styles.app, fullWidthClass)}>
        <DesktopHeader
          className={styles['desktop-header']}
          backUrl={backUrl}
          backPreload={backPreload}
          homePreload={homePreload}
          onLogout={handleLogout}
          withLogout={withLogout}
          pending={pending}
          logoLinkUrl={logoLinkUrl}
        />

        <MobileHeader
          className={styles['mobile-header']}
          backUrl={backUrl}
          backPreload={backPreload}
          menuItems={menuItems}
          heading={mobileHeading}
          onLogout={handleLogout}
          pending={pending}
          withMenu={withMenu}
        />

        <main className={classes(styles.main, fullWidthClass, className)}>
          <div className={styles.notifications}>
            <ErrorNotifications />
            <SuccessNotifications />
          </div>
          {children}
        </main>

        {withFooter && (
          <Footer className={classes(styles.footer, fullWidthClass)} />
        )}
      </div>
    </div>
  );
});
