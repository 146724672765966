import { fetchData } from '../../utils';

const useSeonSession = () => {
  const sendSeonSession = async (sessionId: string) => {
    const data = await fetchData('/client/device-fingerprint-session', {
      method: 'post',
      body: JSON.stringify({ session: sessionId }),
    });

    return data;
  };
  return { sendSeonSession };
};

export default useSeonSession;
