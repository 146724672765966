import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

export type ClientOffer = {
  agreementEndDate: string;
  annualPercentageRate: number;
  capitalizableCommission: number;
  currentPrincipal: number;
  currentPrincipalWithInterest: number;
  currentPrincipalWithInterestAndInitialCommission: number;
  discountAmount: number;
  discountPercent: number;
  discounts: Array<{
    expirationDate: string;
  }>;
  extensionFees: { 7: number; 14: number; 30: number };
  firstPaymentDate: string;
  firstPaymentInterest: number;
  initialCommission: number;
  interestRate: number;
  isAdditionalAmount: boolean;
  loanApplicationEnhancements: Array<
    'NEAR_PRIME' | 'FIXED_MONTHLY_PAYMENT' | 'MONTHLY_PAYMENT' | 'REFINANCING'
  >;
  loanExtensionFees: unknown[];
  monthlyPayment: number;
  newAmountUsedDays: number;
  newAmountWithInterestAndInitialCommission: number;
  newCapitalizedInterest: number;
  newInitialCommission: number;
  newInterest: number;
  newInterestBeforeDiscount: number;
  newPrincipal: number;
  newPrincipalApr: number;
  newPrincipalWithAllCapitalizableFees: number;
  term: number;
  totalCostOfCredit: number;
  totalInterest: number;
  totalPrincipal: number;
  totalRepayableAmount: number;
  totalRoundedAmount: number;
};

type AcceptOfferOptions = {
  body: {
    amount: number;
    term: number;
  };
};

type AcceptOfferCreationResponse = {
  rejected: boolean;
};

type ApplicationConfirmationResponse = {};

type ClientOfferContextType = {
  clientOffers?: ClientOffer[];
  fetchClientOffer: (
    amount: number,
    term: number,
  ) => Promise<ClientOffer | void>;
  setClientOffers: React.Dispatch<
    React.SetStateAction<ClientOffer[] | undefined>
  >;
  acceptClientOffer: ({
    body,
  }: AcceptOfferOptions) => Promise<AcceptOfferCreationResponse | void>;

  declineClientOffer: () => Promise<ApplicationConfirmationResponse | void>;
};

const ClientOfferContext = createContext<ClientOfferContextType>(
  {} as ClientOfferContextType,
);

const useClientOffer = (): ClientOfferContextType =>
  useContext(ClientOfferContext);

const ClientOfferProvider = ({ children }: Props): JSX.Element => {
  const [clientOffers, setClientOffers] = useState<ClientOffer[]>();
  const { showError } = useError();

  const fetchClientOffer = useCallback(
    async (amount: number, term: number): Promise<ClientOffer | void> => {
      try {
        const fetchedClientOffer = await fetchData(
          `/client/application/offer?amount=${amount}&term=${term}`,
          {
            headers: {
              Accept: 'application/json',
            },
          },
        );

        setClientOffers([fetchedClientOffer]);

        return fetchedClientOffer;
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  const acceptClientOffer = useCallback(
    async ({ body }: AcceptOfferOptions): Promise<void> => {
      try {
        await fetchData('/client/application/accept-offer', {
          method: 'post',
          body: JSON.stringify(body),
        });
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  const declineClientOffer =
    useCallback(async (): Promise<ApplicationConfirmationResponse | void> => {
      try {
        const data = await fetchData('/client/application/decline-offer', {
          method: 'post',
        });

        return data;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const clientOfferContextValue = useMemo(
    () => ({
      clientOffers,
      fetchClientOffer,
      setClientOffers,
      acceptClientOffer,
      declineClientOffer,
    }),
    [
      clientOffers,
      fetchClientOffer,
      setClientOffers,
      acceptClientOffer,
      declineClientOffer,
    ],
  );

  return (
    <ClientOfferContext.Provider value={clientOfferContextValue}>
      {children}
    </ClientOfferContext.Provider>
  );
};

export { useClientOffer as default, ClientOfferProvider };
