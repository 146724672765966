// import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

export default function useClientApplicationCheckStatus() {
  const { showError } = useError();
  const fetchClientApplicationCheckStatus =
    useCallback(async (): Promise<ClientApplicationCheckStatus> => {
      try {
        const resp = await fetchData('/client/application/check-status');
        return resp;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const loopClientApplicationCheckStatus = async () => {
    const status = await fetchClientApplicationCheckStatus();
    while (status.completed !== true) {
      const status = await fetchClientApplicationCheckStatus();
      if (status.completed === true) {
        return status;
      }
    }
  };

  return {
    fetchClientApplicationCheckStatus,
    loopClientApplicationCheckStatus,
  };
}

export type ClientApplicationCheckStatus = {
  completed: boolean;
};
