import { fetchData } from '../../utils';
import { useCallback, useMemo } from 'react';
import useError from '../useError';

const useChangePhone = () => {
  const { showError } = useError();

  const requestPhoneVerification = useCallback(
    async ({ body }, recaptchaToken) => {
      try {
        const data = await fetchData(
          '/client/phone',
          {
            method: 'post',
            body: JSON.stringify(body),
          },
          undefined,
          { responseToken: recaptchaToken },
        );

        return data;
      } catch (e) {
        if (e.name !== 'ValidationError') {
          showError();
        }
        throw e;
      }
    },
    [showError],
  );

  const sendCode = useCallback(
    async ({ body }) => {
      try {
        const data = await fetchData('/client/phone-confirmation', {
          method: 'put',
          body: JSON.stringify(body),
        });

        return data;
      } catch (e) {
        if (e.name !== 'ValidationError') {
          showError();
        }
        throw e;
      }
    },
    [showError],
  );

  return useMemo(() => {
    return {
      requestPhoneVerification,
      sendCode,
    };
  }, [requestPhoneVerification, sendCode]);
};

export default useChangePhone;
