export const NOT_FOUND_GENERAL = '*';
export const LOGIN = '/login';
export const TWO_FA = '/2fa';
export const ONE_TIME_TOKEN_LOGIN = '/one-time-token-login';
export const CHECK_DOCUMENTS = '/account/check-documents';
export const FAQ = '/faq';
export const ACTIVE_LOAN = '/account';
export const OVERDUE_LOAN = '/account/overdue';
export const REPEATED_LOAN = '/account/repeated-loan';
export const PERSONAL_DATA_CONFIRM =
  '/account/repeated-loan/personal-data-confirm';
export const LOAN_BUDGET = '/account/repeated-loan/budget';
export const LOAN_APPROVED = '/account/repeated-loan/approve';
export const LOAN_REJECTED = '/account/repeated-loan/reject';
export const LOAN_CONFIRM = '/account/repeated-loan/confirm';
export const DOCUMENT_UPLOAD = '/account/repeated-loan/document-upload';
export const KYC_UPLOAD = '/account/repeated-loan/kyc-upload';
export const DOCUMENT_UPLOAD_PENDING =
  '/account/repeated-loan/document-upload/pending';
export const KYC_UPLOAD_PENDING = '/account/repeated-loan/kyc-pending';
export const NEW_OFFER_INCREASED = '/account/repeated-loan/new-offer-increased';
export const NEW_OFFER_INCREASED_CALCULATOR =
  '/account/repeated-loan/new-offer-increased/calculator';
export const NEW_OFFER_DECREASED = '/account/repeated-loan/new-offer-decreased';
export const ADDITIONAL_AMOUNT = '/account/additional-amount';
export const ADDITIONAL_AMOUNT_LOAN_BUDGET =
  '/account/additional-amount/budget';
export const ADDITIONAL_AMOUNT_APPROVED = '/account/additional-amount/approve';
export const ADDITIONAL_AMOUNT_NEW_OFFER_INCREASE =
  '/account/additonal-amount/new-offer-increased';
export const ADDITIONAL_AMOUNT_NEW_OFFER_INCREASE_CALCULATOR =
  '/account/additonal-amount/new-offer-increased/calculator';
export const ADDITIONAL_AMOUNT_CONFIRM = '/account/additional-amount/confirm';
export const ADDITIONAL_AMOUNT_REJECT = '/account/additional-amount/reject';
export const EXTENSION = '/account/extension';
export const SETTINGS = '/account/settings';
export const PROFILE = '/account/settings';
export const PROFILE_CHANGE_ADDRESS = '/account/settings/change-address';
export const PROFILE_CHANGE_NAME = '/account/settings/change-name';
export const PROFILE_CHANGE_LAST_NAME = '/account/settings/change-last-name';
export const PROFILE_CHANGE_DATE_OF_BIRTH =
  '/account/settings/change-date-of-birth';
export const PROFILE_CHANGE_EMAIL = '/account/settings/change-email';
export const PROFILE_CHANGE_EMAIL_VERIFY =
  '/account/settings/change-email-confirm';
export const PROFILE_CHANGE_EMAIL_DONE = '/account/settings/change-email-done';
export const PROFILE_CHANGE_BANK_ACCOUNT =
  '/account/settings/change-bank-account';
export const PROFILE_CHANGE_PERSONAL_ID =
  '/account/settings/change-personal-id';
export const PROFILE_CHANGE_IDENTITY_DOCUMENT_ID =
  '/account/settings/change-identity-document-id';
export const PROFILE_CHANGE_PHONE = '/account/settings/change-phone';
export const PROFILE_CHANGE_PHONE_VERIFY =
  '/account/settings/change-phone-confirm';
export const PROFILE_CHANGE_PHONE_DONE = '/account/settings/change-phone-done';
export const PROFILE_PASSWORD = '/account/settings/change-password';
export const PASSWORD_CHANGE_SUCCESS = '/account/settings/change-password-done';
export const PASSWORD_RECOVERY = '/password-recovery';
export const PASSWORD_RECOVERY_SENT = '/password-recovery/sent';
export const PASSWORD_RECOVERY_NEW_PASSWORD = '/password-recovery/new-password';
export const PASSWORD_RECOVERY_NEW_PASSWORD_SET =
  '/password-recovery/new-password/done';
export const EXTENSIONS = '/account/extension';
export const REPAYMENT = '/account/payment';
export const LOAN_DETAILS = '/account/loan-details';

export const PSD2_ACCOUNT_CONFIRM = '/account/repeated-loan/account-confirm';
export const PSD2_ACCOUNT_CHANGE_INSTRUCTIONS =
  '/account/repeated-loan/account-instructions';
export const PSD2_BANKS = '/account/repeated-loan/psd/select-bank';
export const PSD2_GUIDE = '/account/repeated-loan/psd/select-bank/helper'; // NOTE: Modal
export const PSD2_CONSENTS = '/account/repeated-loan/psd/consent';
export const PSD2_BANK_ACCOUNT_SELECT =
  '/account/repeated-loan/psd/select-account';
export const PSD2_RECEIVING = '/account/repeated-loan/psd/receiving';
export const PSD2_VERIFIED = '/account/repeated-loan/psd/verified';
export const PSD2_ERROR = '/account/repeated-loan/psd/error';
export const PSD2_APPROVED = '/account/repeated-loan/psd/approved';
