import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

type CreatedDate = {
  day: number;
  month: number;
  year: number;
};

type CreatedTime = {
  hour: number;
  minute: number;
  second: number;
};

type Created = {
  date: CreatedDate;
  time: CreatedTime;
};

type SmsVerificationResponse = {
  smsNumber: number;
};

export type Application = {
  amount: number;
  confirmed: boolean;
  created: Created;
  expired: boolean;
  id: number;
  pendingProcessing: boolean;
  status: 'NEW' | 'OPEN' | 'CLOSED';
  term: number;
  type:
    | 'SMS'
    | 'WEB'
    | 'MOBILE'
    | 'PHONE'
    | 'OFFLINE_TRANSFER'
    | 'OFFLINE_CASH'
    | 'BROKER'
    | 'POS'
    | 'FINTONIC'
    | 'PRESTALO';
  resolutionMessage: string;
  resolutionDetail: string;
  resolutionDetailMessage: string;
  resolution: string;
};

type CreateApplicationOptions = {
  body: {
    amount: number;
    term: number;
    monthlyPayment?: number;
    type?:
      | 'SMS'
      | 'WEB'
      | 'MOBILE'
      | 'PHONE'
      | 'OFFLINE_TRANSFER'
      | 'OFFLINE_CASH'
      | 'BROKER'
      | 'POS'
      | 'FINTONIC'
      | 'PRESTALO';
    source?:
      | 'DESKTOP'
      | 'MOBILE'
      | 'CALL_CENTER'
      | 'BROKER'
      | 'IOS'
      | 'ANDROID'
      | 'HYBRID_IOS'
      | 'HYBRID_ANDROID'
      | 'EXTRA_SERVICE_APPLICATION'
      | 'CRM'
      | 'WEB_OTHER_PRODUCT';
    autoRepay?: boolean; // Whether the loan should be auto-repaid automatically on due date
    typeDetail?:
      | 'MOBILE_APPLICATION'
      | 'ESHOP'
      | 'E_WALLET_ANDROID'
      | 'E_WALLET_IOS'
      | 'E_WALLET_BOON'
      | 'CALL_CENTER'
      | 'CAMPAIGN_DISTRIBUTED'
      | 'LEAD_GENERATOR'
      | 'EXTRA_SERVICE_APPLICATION'
      | 'MULTIFACTOR_AUTHENTIFICATION'
      | 'IOS'
      | 'HYBRID_IOS'
      | 'HYBRID_ANDROID'
      | 'ANDROID'
      | 'WEB_OFFER'
      | 'PHONE_OFFER'
      | 'AFFILIATE'
      | 'CRM'
      | 'E_MONEY'
      | 'MOBILE_APPLICATION_OFFER'
      | 'REFINANCE'
      | 'OFFLINE_OFFER';
    discountPercent?: number;
    newSourceVersion?: boolean; // Whether the application came from a new front end, used for data analytics only
    partnerRepresentativeId?: number;
    ioBlackBox?: string;
    firstPaymentDueDate?: string;
    calculatorId?: number;
  };
};

type CreatedApplicationResponse = {
  rejected: boolean;
};

type ApplicationProposals = {
  bestOfferProposal: {
    amount: number;
    term: number;
  };
};

type ApplicationContextType = {
  application?: Application;
  applicationProposals?: ApplicationProposals;
  fetchApplication: () => Promise<Application | void>;
  fetchApplicationProposals: () => Promise<ApplicationProposals | void>;
  createApplication: ({
    body,
  }: CreateApplicationOptions) => Promise<CreatedApplicationResponse | void>;
  confirmApplication: () => Promise<ConfirmApplicationResponse | void>;
  acceptLatestTerms: () => Promise<void>;
  triggerSmsVerification: () => Promise<SmsVerificationResponse | void>;
};

export type ConfirmApplicationResponse = {
  self: string;
  clientLink: string;
  rejected: boolean;
  manual: boolean;
  scorecardResolution: string;
};

const ApplicationContext = createContext<ApplicationContextType>(
  {} as ApplicationContextType,
);

const useApplication = (): ApplicationContextType =>
  useContext(ApplicationContext);

const ApplicationProvider = ({ children }: Props): JSX.Element => {
  const [application, setApplication] = useState<Application>();
  const [applicationProposals, setApplicationProposals] = useState<
    ApplicationProposals | undefined
  >();

  const { showError } = useError();

  const fetchApplication =
    useCallback(async (): Promise<Application | void> => {
      try {
        const fetchedApplicationn = await fetchData('/client/application', {
          headers: {
            Accept: 'application/vnd.4finance.web.v1.hal+json',
          },
        });
        setApplication(fetchedApplicationn);
        return fetchedApplicationn;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const fetchApplicationProposals =
    useCallback(async (): Promise<ApplicationProposals | void> => {
      try {
        const fetchedClientApplicationProposals = await fetchData(
          '/client/application/proposals',
        );

        setApplicationProposals(fetchedClientApplicationProposals);
        return fetchedClientApplicationProposals;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const createApplication = useCallback(
    async ({
      body,
    }: CreateApplicationOptions): Promise<CreatedApplicationResponse | void> => {
      try {
        const createdApplicationResponse = await fetchData(
          '/client/application',
          {
            method: 'post',
            body: JSON.stringify(body),
          },
        );

        return createdApplicationResponse;
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  const confirmApplication =
    useCallback(async (): Promise<ConfirmApplicationResponse | void> => {
      try {
        const data = await fetchData('/client/application', {
          method: 'put',
        });

        return data;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const acceptLatestTerms = useCallback(async () => {
    try {
      const data = await fetchData('/client/accept-latest-agreement-version', {
        method: 'post',
      });

      return data;
    } catch (e) {
      showError();
      throw e;
    }
  }, [showError]);

  const triggerSmsVerification =
    useCallback(async (): Promise<SmsVerificationResponse | void> => {
      try {
        const fetchedApplicationSmsNumber = await fetchData(
          '/client/application/send-verification-code',
          { method: 'post' },
        );
        return fetchedApplicationSmsNumber;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const applicationContextValue = useMemo(
    () => ({
      application,
      applicationProposals,
      fetchApplication,
      fetchApplicationProposals,
      createApplication,
      confirmApplication,
      acceptLatestTerms,
      triggerSmsVerification,
    }),
    [
      application,
      applicationProposals,
      fetchApplication,
      fetchApplicationProposals,
      createApplication,
      confirmApplication,
      acceptLatestTerms,
      triggerSmsVerification,
    ],
  );

  return (
    <ApplicationContext.Provider value={applicationContextValue}>
      {children}
    </ApplicationContext.Provider>
  );
};

export { useApplication as default, ApplicationProvider };
