import { memo, useEffect } from 'react';
import { Notification } from '../../../components';
import { useLocation } from 'react-router-dom';
import classes from 'classnames';
import styles from './ErrorNotifications.module.scss';
import useError from '../../../hooks/useError';

type Props = {
  className?: string;
};

export default memo(function ErrorNotifications({ className }: Props) {
  const { error, removeError } = useError();
  const location = useLocation();

  useEffect(() => {
    removeError();
  }, [location.pathname, removeError]);

  if (!error) return null;

  return (
    <Notification
      dataTest="error-notification"
      className={classes(styles.notification, className)}
      type="error"
      onClose={removeError}
    >
      {error.message}
    </Notification>
  );
});
