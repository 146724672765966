import { memo, ReactNode } from 'react';
import classes from 'classnames';
import styles from './Paragraph.module.scss';

export type Props = {
  children: ReactNode;
  className?: string;
  dataTest?: string;
  type?: 'regular' | 'regular-bold' | 'small' | 'small-bold';
};

export default memo(function Paragraph({
  children,
  className,
  dataTest,
  type = 'regular',
}: Props) {
  return (
    <p
      className={classes(styles.paragraph, styles[type], className)}
      data-test={dataTest}
    >
      {children}
    </p>
  );
});
