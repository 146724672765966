import * as R from '../../../../Router/routes';
import { AsyncActiveLoan } from '../../../../pages/ActiveLoan/async';
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron-sharp.svg';
import { LoadableComponentMethods } from '@loadable/component';
import { ReactComponent as Logo } from '../../../../assets/icons/logo.svg';
import { ReactComponent as LogoutIcon } from '../../../../assets/icons/logout.svg';
import { memo } from 'react';
import classes from 'classnames';
import Link from '../../../Link/Link';
import styles from './DesktopHeader.module.scss';
import translate from '../../../../utils/translate';

export type Props = {
  backUrl?: string;
  backPreload?: LoadableComponentMethods<unknown>['preload'];
  withLogout?: boolean;
  onLogout?: () => void;
  className?: string;
  pending?: boolean;
  logoLinkUrl?: string;
  homePreload?: LoadableComponentMethods<unknown>['preload'];
};

export default memo(function DesktopHeader({
  backUrl,
  backPreload,
  withLogout,
  onLogout,
  className,
  pending,
  logoLinkUrl = R.ACTIVE_LOAN,
  homePreload = AsyncActiveLoan.preload,
}: Props) {
  function preload() {
    backPreload?.();
  }
  function preloadHomepage() {
    homePreload?.();
  }

  return (
    <header className={classes(styles.header, className)}>
      <div className={styles.item}>
        {backUrl && (
          <Link dataTest="flow-back" to={backUrl} onMouseEnter={preload}>
            <ChevronIcon className={styles.chevron} />
            <span>{translate('desktop_header.back')}</span>
          </Link>
        )}
      </div>
      <div className={classes(styles.item, styles.center)}>
        <Link
          to={logoLinkUrl}
          onMouseEnter={preloadHomepage}
          dataTest="desktop-back-link"
        >
          <Logo />
        </Link>
      </div>
      <div className={classes(styles.item, styles.right)}>
        {withLogout && (
          <Link disabled={pending} onClick={onLogout}>
            <span>{translate('desktop_header.logout')}</span>
            <LogoutIcon className={styles.logout} />
          </Link>
        )}
      </div>
    </header>
  );
});
