import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

export type ClientBudget = {
  monthlyIncome: number;
  monthlyExpenses: number;
  numberOfEmployedHouseholdMembers: number;
  numberOfHouseholdMembers: number;
};

export type PostClientBudgetRequest = {
  body: ClientBudget;
};

type ClientBudgetContextType = {
  clientBudgetInfo?: ClientBudget;
  fetchClientBudgetInfo: () => Promise<ClientBudget | void>;
  postClientBudget: ({
    body,
  }: PostClientBudgetRequest) => Promise<ClientBudget | void>;
};

const ClientBudgetContext = createContext<ClientBudgetContextType>(
  {} as ClientBudgetContextType,
);

const useClientBudget = (): ClientBudgetContextType =>
  useContext(ClientBudgetContext);

const ClientBudgetProvider = ({ children }: Props): JSX.Element => {
  const [clientBudgetInfo, setClientBudgetInfo] = useState<ClientBudget>();
  const { showError } = useError();

  const fetchClientBudgetInfo =
    useCallback(async (): Promise<ClientBudget | void> => {
      try {
        const fetchedClientBudgetInfo = await fetchData(`/client/budget/info`, {
          headers: {
            Accept: 'application/json',
          },
        });

        setClientBudgetInfo(fetchedClientBudgetInfo);

        return fetchedClientBudgetInfo;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const postClientBudget: ClientBudgetContextType['postClientBudget'] =
    useCallback(
      async ({ body }) => {
        try {
          const resultClientBudget = await fetchData(`/client/budget`, {
            method: 'post',
            headers: {
              Accept: 'application/json',
            },
            body: JSON.stringify(body),
          });

          setClientBudgetInfo(resultClientBudget);
          return resultClientBudget;
        } catch (e) {
          showError();
          throw e;
        }
      },
      [showError],
    );

  const clientBudgetContextValue = useMemo(
    () => ({
      clientBudgetInfo,
      fetchClientBudgetInfo,
      postClientBudget,
    }),
    [clientBudgetInfo, fetchClientBudgetInfo, postClientBudget],
  );

  return (
    <ClientBudgetContext.Provider value={clientBudgetContextValue}>
      {children}
    </ClientBudgetContext.Provider>
  );
};

export { useClientBudget as default, ClientBudgetProvider };
