import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import Cookie from 'js-cookie';
import fetchData from '../../utils/fetchData';
import pMemoize from 'p-memoize';
import useError from '../useError';

const memoFetchData = pMemoize(fetchData, { maxAge: 1000 });

type Props = {
  children: ReactNode;
};

export type Client = {
  id: number;
  number: string;
  status:
    | 'UNKNOWN'
    | 'REGISTERED'
    | 'IDENTIFIED'
    | 'DELETED'
    | 'VALIDATED'
    | 'ACTIVE'
    | 'NOT_IDENTIFIED';
  declaredAddress: {
    fullAddress: string;
    postalCode: string;
    location1: string;
  };
  actualAddress: {
    fullAddress: string;
    postalCode: string;
    location1: string;
  };
  mobilePhoneVerified: boolean;
  firstName: string;
  lastName: string;
  fullName: string;
  personalId: string;
  identificationNumber: string;
  identityDocumentId: string;
  dateOfBirth: string;
  age: number;
  identifiedBy:
    | 'BANK'
    | 'MANUAL'
    | 'ID_SERVICE'
    | 'BROKER'
    | 'EMAIL'
    | 'IMMEDIATE_TRANSFER'
    | 'FINTONIC'
    | 'PRESTALO'
    | 'NOT_IDENTIFIED';
  registeredBy:
    | 'WEB'
    | 'OFFLINE'
    | 'BROKER'
    | 'CALL_CENTER'
    | 'LEAD_GENERATOR'
    | 'WEB_OTHER_PRODUCT';
  blocked: boolean;
  identified: boolean;
  bankAccount: string;
  openPrincipalAmount: number;
  homePhone: string;
  validationResolutionDetail:
    | 'PERSONAL_ID_MISSING'
    | 'EXPIRED_IDENTITY_DOCUMENT'
    | 'ERROR'
    | 'CHECK_PAYSLIP'; // NOTE: This is not the full enum, instead only the values currently used on FE side
  productNumber: number; // TODO: change to specific product number types
  additionalAmount: boolean;
  creditLimit: number;
  hasOpenLoan: boolean;
  _embedded: {
    mobilePhone: {
      mobilePhone: string;
    };
    email: {
      email: string;
    };
  };
};

type ClientContextType = {
  client: Client | undefined;
  fetchClient: () => Promise<Client | void>;
  postAnalyticsCookies: () => Promise<void>;
};

const ClientContext = createContext<ClientContextType>({} as ClientContextType);

const useClient = (): ClientContextType => useContext(ClientContext);

const ClientProvider = ({ children }: Props): JSX.Element => {
  const [client, setClient] = useState<Client | undefined>();
  const { showError } = useError();

  const fetchClient = useCallback(async (): Promise<Client | void> => {
    try {
      const fetchedClient = await memoFetchData('/client');
      setClient(fetchedClient);
      return fetchedClient;
    } catch (e) {
      showError();
      throw e;
    }
  }, [showError]);

  const getAnalyticsCookies = useCallback(() => {
    const analyticsCookies = [];

    const ga = Cookie.get('_ga');

    if (ga) {
      analyticsCookies.push({
        name: 'ga',
        value: ga,
      });
    }

    const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
    if (!measurementId) {
      return analyticsCookies;
    }

    const [, secondPart] = measurementId.split('-');

    if (!secondPart) {
      return analyticsCookies;
    }

    analyticsCookies.push({
      name: `ga_${secondPart}`,
      value: Cookie.get(`_ga_${secondPart}`),
    });
    return analyticsCookies;
  }, []);

  /** Used for tracking client analytic events within COLA */
  const postAnalyticsCookies = useCallback(async () => {
    const gaCookies = getAnalyticsCookies();

    if (gaCookies.length === 0) {
      return;
    }

    try {
      await fetchData('/client/cookies', {
        method: 'post',
        body: JSON.stringify(gaCookies),
        headers: {
          'Content-Type': 'application/vnd.analytics.v2+json',
        },
      });
    } catch (e) {
      showError();
      throw e;
    }
  }, [getAnalyticsCookies, showError]);

  const clientContextValue = useMemo(
    () => ({
      client,
      fetchClient,
      postAnalyticsCookies,
    }),
    [client, fetchClient, postAnalyticsCookies],
  );

  return (
    <ClientContext.Provider value={clientContextValue}>
      {children}
    </ClientContext.Provider>
  );
};

export { useClient as default, ClientProvider };
