import * as R from '../Router/routes';
import { AsyncActiveLoan } from '../pages/ActiveLoan/async';
import { AsyncAdditionalAmount } from '../pages/AdditionalAmount/async';
import { AsyncOverdueLoan } from '../pages/OverdueLoan/async';
import { AsyncPayments } from '../pages/Payments/async';
import { AsyncProfile } from '../pages/Profile/async';
import { AsyncRepeatedLoan } from '../pages/RepeatedLoan/async';
import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg';
import { ReactComponent as DashboardIcon } from '../assets/icons/dashboard.svg';
import { ReactComponent as MoneyIcon } from '../assets/icons/money.svg';
import { ReactComponent as ProfileIcon } from '../assets/icons/profile.svg';
import { translate } from '../utils';
import { useClient, useLatestLoan } from './webapi';
import { useEffect, useMemo } from 'react';

type Props = {
  shouldFetchMenu?: boolean;
};

const useMenuItems = (props: Props = { shouldFetchMenu: true }) => {
  const { latestLoan, fetchLatestLoan } = useLatestLoan();
  const { client, fetchClient } = useClient();

  useEffect(() => {
    (async () => {
      try {
        if (props?.shouldFetchMenu) {
          await Promise.all([
            latestLoan === undefined ? fetchLatestLoan() : null,
            client === undefined ? fetchClient() : null,
          ]);
        }
      } catch {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuItems = useMemo(() => {
    let dashboardURL = R.ACTIVE_LOAN;
    let dashboardAsync:
      | typeof AsyncActiveLoan
      | typeof AsyncRepeatedLoan
      | typeof AsyncOverdueLoan = AsyncActiveLoan;

    if (latestLoan == null) {
      dashboardURL = R.REPEATED_LOAN;
      dashboardAsync = AsyncRepeatedLoan;
    } else if (latestLoan.overDueDays > 0) {
      dashboardURL = R.OVERDUE_LOAN;
      dashboardAsync = AsyncOverdueLoan;
    }

    return [
      {
        title: translate(latestLoan ? 'menu.active_loan' : 'menu.new_loan'),
        url: dashboardURL,
        additionalActiveState: [R.LOAN_DETAILS, R.REPAYMENT, R.OVERDUE_LOAN],
        icon: <DashboardIcon />,
        dataTest: 'dashboard',
        preload: dashboardAsync.preload,
      },
      {
        title: translate('menu.additional_amount'),
        url: R.ADDITIONAL_AMOUNT,
        icon: <MoneyIcon />,
        disabled:
          !client?.additionalAmount ||
          latestLoan?.debtCollectLoanAgreement ||
          latestLoan?.hasActiveDebtSchedule,
        dataTest: 'additional-amount',
        preload: AsyncAdditionalAmount.preload,
      },
      {
        title: translate('menu.extension'),
        url: R.EXTENSION,
        disabled:
          !latestLoan?.isExtensionPossible ||
          latestLoan?.debtCollectLoanAgreement ||
          latestLoan?.hasActiveDebtSchedule,
        icon: <CalendarIcon />,
        dataTest: 'extension',
        preload: AsyncPayments.preload,
      },
      {
        title: translate('menu.profile'),
        url: R.PROFILE,
        icon: <ProfileIcon />,
        dataTest: 'profile',
        preload: AsyncProfile.preload,
      },
    ];
  }, [latestLoan, client?.additionalAmount]);

  return menuItems;
};

export default useMenuItems;
