import { Loader } from '../../components';
import loadable from '@loadable/component';

export const AsyncPasswordRecoveryNewPasswordSet = loadable(
    () =>
        import(
            './PasswordRecoveryNewPasswordSet'
            ),
    {
        fallback: <Loader />,
    },
);