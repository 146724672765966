/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs'

export default function initDatadogLogs() {
  const errors: string[] = []

  if (!process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
    errors.push('DatadogLogs: REACT_APP_DATADOG_CLIENT_TOKEN not provided!');
  }

  if (!process.env.REACT_APP_DATADOG_SITE) {
    errors.push('DatadogLogs: REACT_APP_DATADOG_SITE not provided!');
  }

  if (!process.env.REACT_APP_DATADOG_SERVICE_NAME) {
    errors.push('DatadogLogs: REACT_APP_DATADOG_SERVICE_NAME not provided!');
  }

  if (errors.length) {
    errors.forEach((err) => { console.error(err) })
    return
  }

  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
    env: process.env.REACT_APP_ENVIRONMENT,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  })
}