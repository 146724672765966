import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { memo, ReactNode } from 'react';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import classes from 'classnames';
import styles from './Notification.module.scss';

export type Props = {
  children: ReactNode;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  dataTest?: string;
  type?: 'success' | 'error';
  className?: string;
  contentClassName?: string;
};

export default memo(function Notification({
  children,
  onClose,
  dataTest,
  type = 'success',
  className,
  contentClassName,
}: Props) {
  return (
    <div
      className={classes(styles.container, styles[type], className)}
      data-test={dataTest}
    >
      <div className={classes(styles.content, contentClassName)}>
        <div className={styles['type-icon']}>
          {type === 'error' && <ErrorIcon />}
          {type === 'success' && <SuccessIcon />}
        </div>
        {children}
      </div>
      {onClose && (
        <button type="button" className={styles.close} onClick={onClose}>
          <CloseIcon className={styles.icon} />
        </button>
      )}
    </div>
  );
});
