import { Loader } from '../../components';
import loadable from '@loadable/component';

export const AsyncLogin = loadable(
    () => import('./Login'),
    {
        fallback: <Loader />,
    },
);

export const AsyncOneTimeTokenLogin = loadable(
    () => import('./OneTimeTokenLogin'),
    {
        fallback: <Loader />,
    },
);