import { ApplicationProvider } from './hooks/webapi/useApplication';
import { ApplicationUpsaleProvider } from './hooks/webapi/useApplicationUpsale';
import { AuthProvider } from './hooks/webapi/useAuth';
import { BanksProvider } from './hooks/webapi/useBanks';
import { ClientBudgetProvider } from './hooks/webapi/useClientBudget';
import { ClientConstraintsProvider } from './hooks/webapi/useClientConstraints';
import { ClientHouseholdProvider } from './hooks/webapi/useClientHousehold';
import { ClientOfferProvider } from './hooks/webapi/useClientOffer';
import { ClientProvider } from './hooks/webapi/useClient';
import { ConfirmationProvider } from './hooks/webapi/useConfirmation';
import { ConsentsProvider } from './hooks/webapi/useConsents';
import { DiscountsProvider } from './hooks/webapi/useDiscounts';
import { ErrorProvider } from './hooks/useError';
import { LatestLoanProvider } from './hooks/webapi/useLatestLoan';
import { LegalDocumentProvider } from './hooks/webapi/useLegalDocuments';
import { NotificationProvider } from './hooks/useNotification';
import { PSD2ProxyProvider } from './hooks/webapi/usePSD2Proxy';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

function StateProvider({ children }: Props) {
  return (
    <ErrorProvider>
      <NotificationProvider>
        <AuthProvider>
          <ClientProvider>
            <ClientBudgetProvider>
              <ClientConstraintsProvider>
                <ClientHouseholdProvider>
                  <ClientOfferProvider>
                    <ConsentsProvider>
                      <BanksProvider>
                        <LegalDocumentProvider>
                          <DiscountsProvider>
                            <ApplicationProvider>
                              <LatestLoanProvider>
                                <ApplicationUpsaleProvider>
                                  <ConfirmationProvider>
                                    <PSD2ProxyProvider>
                                      {children}
                                    </PSD2ProxyProvider>
                                  </ConfirmationProvider>
                                </ApplicationUpsaleProvider>
                              </LatestLoanProvider>
                            </ApplicationProvider>
                          </DiscountsProvider>
                        </LegalDocumentProvider>
                      </BanksProvider>
                    </ConsentsProvider>
                  </ClientOfferProvider>
                </ClientHouseholdProvider>
              </ClientConstraintsProvider>
            </ClientBudgetProvider>
          </ClientProvider>
        </AuthProvider>
      </NotificationProvider>
    </ErrorProvider>
  );
}

export default StateProvider;
