import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

type TermLimit = {
  amountFrom: number;
  amountTo: number;
  termFrom: number;
  termTo: number;
  limitType: 'SPL' | 'IL';
};

export type ClientConstraints = {
  amountInterval: {
    min: number;
    max: number;
    step: number;
    defaultValue: number;
  };
  termInterval: {
    min: number;
    max: number;
    step: number;
    defaultValue: number;
  };
  termLimits: Array<TermLimit>;
};

type ClientConstraintsContextType = {
  clientConstraints: ClientConstraints | undefined;
  fetchClientConstraints: () => Promise<ClientConstraints | void>;
  setClientConstraints: React.Dispatch<
    React.SetStateAction<ClientConstraints | undefined>
  >;
};

const ClientConstraintsContext = createContext<ClientConstraintsContextType>(
  {} as ClientConstraintsContextType,
);

const useClientConstraints = (): ClientConstraintsContextType =>
  useContext(ClientConstraintsContext);

const ClientConstraintsProvider = ({ children }: Props): JSX.Element => {
  const [clientConstraints, setClientConstraints] = useState<
    ClientConstraints | undefined
  >();
  const { showError } = useError();

  /** Fetches client calculator constraints based on product number. */
  const fetchClientConstraints =
    useCallback(async (): Promise<ClientConstraints | void> => {
      try {
        const fetchedClientConstraints: ClientConstraints = await fetchData(
          '/client/application/constraints',
        );

        setClientConstraints(fetchedClientConstraints);

        return fetchedClientConstraints;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const clientContextValue = useMemo(
    () => ({
      clientConstraints,
      fetchClientConstraints,
      setClientConstraints,
    }),
    [clientConstraints, fetchClientConstraints],
  );

  return (
    <ClientConstraintsContext.Provider value={clientContextValue}>
      {children}
    </ClientConstraintsContext.Provider>
  );
};

export { useClientConstraints as default, ClientConstraintsProvider };
