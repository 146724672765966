import './App.scss';
import { useAuth } from './hooks/webapi';
import { useEffect } from 'react';
import AppRouter from './Router/Router';
import Cookie from 'js-cookie';
import initDatadogLogs from './datadog/logs';
// import initDatadogRUM from './datadog/rum';

const events = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
];

export default function App() {
  const { logout } = useAuth();
  // Do not uncomment until specifically asked
  // initDatadogRUM();
  initDatadogLogs();

  let timer: NodeJS.Timeout;
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });

      logout();
    }, 900000);
  };

  useEffect(() => {
    const handleOnLoadEvent = () => {
      const cookieToken = Cookie.get('token');
      const cookieCrossLoginToken = Cookie.get('crossLoginToken');

      if (Boolean(cookieToken) && !Boolean(sessionStorage.getItem('token'))) {
        sessionStorage.setItem('token', cookieToken || '');
      }

      if (
        Boolean(cookieCrossLoginToken) &&
        !Boolean(sessionStorage.getItem('crossLoginToken'))
      ) {
        sessionStorage.setItem('crossLoginToken', cookieCrossLoginToken || '');
      }
      Cookie.remove('token');
      Cookie.remove('crossLoginToken');
    };

    window.addEventListener('load', handleOnLoadEvent);

    return () => {
      window.removeEventListener('load', handleOnLoadEvent);
    };
  }, []);

  return <AppRouter />;
}
