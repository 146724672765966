import {
  memo,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classes from 'classnames';
import Lottie from 'react-lottie';
import Ripples from 'react-ripples';
import styles from './DocumentUpload.module.scss';

import { ReactComponent as Document2IconPath } from '../../assets/icons/document-v2.svg';
import { ReactComponent as GreenPlusIcon } from '../../assets/icons/plus-circle-v2.svg';
import { ReactComponent as HazardIcon } from '../../assets/icons/hazard.svg';
import { ReactComponent as IdBackIcon } from '../../assets/icons/id-back-v2.svg';
import { ReactComponent as IdFrontIcon } from '../../assets/icons/id-front-v2.svg';
import { ReactComponent as RedPlusIcon } from '../../assets/icons/plus-circle-red.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import BigDoneIcon from '../../assets/lottie/big-done-icon.json';
import DocumentIconPath from '../../assets/icons/document.svg';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const doneIconLottieOptions = {
  loop: false,
  autoplay: true,
  animationData: BigDoneIcon,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

type Props = {
  type: 'ID_FRONT' | 'ID_BACK' | 'DOCUMENT';
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  className?: string;
  error?: string;
  isTouched?: boolean;
  value?: File | null;
  onDelete: (name: string) => void;
  dataTest?: string;
  name: string;
  containerClassName?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  isNotInput?: boolean;
  innerRef?: any;
  title: string;
  subtitle?: string;
};

export type DocumentUploadProps = Props;

const UPLOAD_TYPE = {
  ID_FRONT: {
    icon: IdFrontIcon,
  },
  ID_BACK: {
    icon: IdBackIcon,
  },
  DOCUMENT: {
    icon: Document2IconPath,
  },
};

// NOTE: Returns file data and filetype
const getBase64FromFile = (file: File): Promise<[string, string]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      if (typeof reader.result === 'string') {
        resolve([reader.result, file.type]);
      }
    });

    reader.addEventListener('error', () => {
      reject();
    });

    reader.readAsDataURL(file);
  });
};

export const SUPPORTED_FORMATS = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/tiff',
];

export default memo(function DocumentUpload({
  type,
  value,
  onChange,
  onBlur,
  className,
  error,
  isTouched,
  onDelete,
  dataTest,
  name,
  containerClassName,
  onClick,
  isNotInput = false,
  innerRef,
  title,
  subtitle,
}: Props) {
  const [img, setImg] = useState('');
  const [fileType, setFileType] = useState('');
  const Icon = UPLOAD_TYPE[type].icon;
  const showError = isTouched && error;

  useEffect(() => {
    const createImg = async () => {
      if (!value) return;

      const [convertedImg, type] = await getBase64FromFile(value);
      setImg(convertedImg);
      setFileType(type);
    };

    createImg();
  }, [value]);

  const handleDelete = useCallback(() => {
    if (!onDelete) return;

    onDelete(name);
  }, [name, onDelete]);

  const Input = useMemo(() => {
    return isNotInput ? (
      <>
        <div onClick={onClick} className={styles.input} />
        <Icon className={styles.icon} />
      </>
    ) : (
      <>
        <input
          ref={innerRef}
          name={name}
          className={styles.input}
          onChange={onChange}
          onDrop={(e) => e.preventDefault()}
          onBlur={onBlur}
          type="file"
          accept={SUPPORTED_FORMATS.join(',')}
        />
        <Icon className={styles.icon} />
      </>
    );
  }, [isNotInput, innerRef, Icon, name, onClick, onChange, onBlur]);

  const PlusIcon =
    isTouched && error ? (
      <RedPlusIcon />
    ) : (
      <GreenPlusIcon
        className={classes(styles.plus, showError && styles.error)}
      />
    );

  return (
    <div className={className}>
      <Ripples
        className={classes(styles.ripples, !value && styles.focusable)}
        color={value ? 'rgba(0, 0, 0, 0)' : 'rgba(92, 182, 14, 0.3)'}
      >
        <div
          className={classes(
            styles.container,
            containerClassName,
            showError && styles.error,
          )}
          data-test={dataTest}
        >
          {value ? (
            <div
              style={{
                backgroundImage:
                  fileType === 'image/png'
                    ? `url(${img})`
                    : `url(${img}), url(${DocumentIconPath})`,
              }}
              className={styles.preview}
            >
              {!showError && (
                <div className={styles['lottie-wrapper']}>
                  <Lottie options={doneIconLottieOptions} />
                </div>
              )}
            </div>
          ) : (
            Input
          )}
          <div className={styles.text}>
            <label className={classes(styles.label, showError && styles.error)}>
              {title}
            </label>
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
          </div>
          {value ? (
            <button
              className={styles.delete}
              type="button"
              data-test="delete-icon"
              onClick={handleDelete}
            >
              <TrashIcon />
            </button>
          ) : (
            PlusIcon
          )}
        </div>
      </Ripples>
      {showError && (
        <div data-test="document-error" className={styles['error-wrapper']}>
          <HazardIcon className={styles['error-icon']} />
          <ErrorMessage
            className={styles.error}
            errors={error}
            dataTest={`${dataTest}-error`}
          />
        </div>
      )}
    </div>
  );
});
