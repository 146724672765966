import * as R from '../../Router/routes';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { translate } from '../../utils';
import fetchData, { defaultOptions, fetchRetry } from '../../utils/fetchData';

type Props = {
  children: ReactNode;
};

type LoginResponse = {
  authorizationToken: string;
  fromTemporaryPassword: boolean;
  accessVerificationRequired?: boolean;
};

type CrossLoginParams = {
  invalidateSession?: boolean;
};

type AuthContextType = {
  login: (email: string, password: string) => Promise<LoginResponse>;
  logout: () => Promise<void>;
  crossLoginRedirect: (params?: CrossLoginParams) => void;
  crossLoginAuth: (token: string) => Promise<LoginResponse>;
  clearStorage: () => void;
  postAccessVerification: (
    code: string,
    encodedCredentials: string,
  ) => Promise<LoginResponse>;
  resend: (encodedCredentials: string) => void;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const useAuth = (): AuthContextType => useContext(AuthContext);

const AuthProvider = ({ children }: Props): JSX.Element => {
  const resend = useCallback(async (encodedCredentials: string) => {
    const path = '/token/basic';
    const url = `${process.env.REACT_APP_WEB_API}${path}`;
    await fetchRetry(url, {
      ...defaultOptions,
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${encodedCredentials}`,
      },
    });
  }, []);

  const postAccessVerification = useCallback(
    async (accessCode: string, encodedCredentials: string) => {
      const path = '/token/access-verification';
      const url = `${process.env.REACT_APP_WEB_API}${path}`;

      const res = await fetchRetry(url, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${encodedCredentials}`,
        },
        body: `accessCode=${accessCode}`,
      });

      if (!res.ok) {
        try {
          const data = await res.json();

          datadogLogs.logger.error(`request: ${path}`, {
            request_url: url,
            error: data,
          });
        } catch (exception) {
          datadogLogs.logger.error(`request: ${path} response parse`, {
            request_url: url,
            error: exception,
          });
        }

        if (res.status === 400 || res.status === 401) {
          throw new Error(translate('error.validation.incorrect_code'));
        }

        if (res.status === 406) {
          throw new Error(translate('error.token_expired'));
        }

        if (res.status === 429) {
          throw new Error(translate('error.validation.too_many_attempts'));
        }

        throw new Error(translate('error.generic'));
      }

      const data = await res.json();

      const authorizationToken = btoa(`${data.username}:${data.token}`);
      const fromTemporaryPassword = data.fromTemporaryPassword;

      sessionStorage.setItem('token', authorizationToken);
      sessionStorage.setItem('fromTemporaryPassword', fromTemporaryPassword);

      return {
        authorizationToken,
        fromTemporaryPassword,
      };
    },
    [],
  );

  const login = useCallback(async (email: string, password: string) => {
    try {
      const encodedCredentials = btoa(`${email}:${password}`);
      const path = '/token/basic';
      const url = `${process.env.REACT_APP_WEB_API}${path}`;

      // TODO: utilize with fetchData method
      const res = await fetchRetry(url, {
        ...defaultOptions,
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Basic ${encodedCredentials}`,
        },
      });
      if (!res.ok) {
        try {
          const data = await res.json();

          datadogLogs.logger.error(`request: ${path}`, {
            request_url: url,
            error: data,
          });
        } catch (exception) {
          datadogLogs.logger.error(`request: ${path} response parse`, {
            request_url: url,
            error: exception,
          });
        }

        if (res.status === 401) {
          throw new Error(translate('error.validation.unauthorized'));
        }

        if (res.status === 403) {
          throw new Error(translate('error.validation.login_unavailable'));
        }

        throw new Error(translate('error.generic'));
      }

      const data = await res.json();

      const authorizationToken = btoa(`${data.username}:${data.token}`);
      const fromTemporaryPassword = data.fromTemporaryPassword;
      const accessVerificationRequired = data.accessVerificationRequired;

      sessionStorage.setItem('token', authorizationToken);
      sessionStorage.setItem('fromTemporaryPassword', fromTemporaryPassword);

      return {
        authorizationToken,
        fromTemporaryPassword,
        accessVerificationRequired,
      };
    } catch (e) {
      throw e;
    }
  }, []);

  const clearStorage = useCallback(() => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('fromTemporaryPassword');
  }, []);

  const logout = useCallback(
    async (redirect = true) => {
      try {
        if (sessionStorage.getItem('token')) {
          sessionStorage.removeItem('discountBannerShowed');
          await fetchData('/token/invalidate', {
            method: 'delete',
          });

          clearStorage();
        }
      } catch (e) {
        throw e;
      } finally {
        // If invalidate request fails for some reason, at least remove token
        clearStorage();

        if (redirect) {
          const homeURL =
            process.env.NODE_ENV === 'development'
              ? R.LOGIN
              : process.env.REACT_APP_STATIC_HOMEPAGE || R.LOGIN;

          window.location.assign(homeURL);
        }
      }
    },
    [clearStorage],
  );

  const crossLoginAuth = useCallback(async (oneTimeToken: string) => {
    try {
      const {
        username,
        token,
        fromTemporaryPassword,
        accessVerificationRequired,
      } = await fetchData('/token/one-time', {
        method: 'post',
        body: JSON.stringify({
          oneTimeToken,
        }),
      });

      const authorizationToken = btoa(`${username}:${token}`);

      sessionStorage.setItem('token', authorizationToken);
      sessionStorage.setItem('fromTemporaryPassword', fromTemporaryPassword);

      return {
        authorizationToken,
        fromTemporaryPassword,
        accessVerificationRequired,
      };
    } catch (e) {
      throw e;
    }
  }, []);

  const crossLoginRedirect = useCallback(
    async (params: CrossLoginParams = {}) => {
      const { invalidateSession = true } = params;

      try {
        const { oneTimeToken } = await fetchData(
          '/client/security/one-time-token',
          {
            method: 'post',
          },
        );

        if (invalidateSession) {
          await logout(false);
        }

        const queryString = window.location.search
          ? `&${window.location.search.substring(1)}`
          : '';
        const redirectURL = `${process.env.REACT_APP_REGISTRATION_URL}/one-time-token-login?token=${oneTimeToken}${queryString}`;

        // disable cross-origin redirect for cypress
        if ((window as any).Cypress) {
          return console.log(redirectURL); // eslint-disable-line no-console
        }

        window.location.assign(redirectURL);
      } catch (e) {}
    },
    [logout],
  );

  const profileContextValue = useMemo(
    () => ({
      login,
      logout,
      crossLoginRedirect,
      crossLoginAuth,
      clearStorage,
      postAccessVerification,
      resend,
    }),
    [
      login,
      logout,
      crossLoginRedirect,
      crossLoginAuth,
      clearStorage,
      postAccessVerification,
      resend,
    ],
  );

  return (
    <AuthContext.Provider value={profileContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { useAuth as default, AuthProvider };
