import classes from 'classnames';
import styles from './Loader.module.scss';

export type Props = {
  outline?: boolean;
  className?: string;
};

export default function Loader({ outline = false, className }: Props) {
  return (
    <div className={classes(styles.wrapper, className)}>
      <div className={styles.loader}>
        <div className={outline ? styles['circle-gray'] : styles.circle} />
        <div className={outline ? styles['circle-gray'] : styles.circle} />
      </div>
    </div>
  );
}
