import { memo, useEffect } from 'react';
import { Notification } from '../../../components';
import { useLocation } from 'react-router-dom';
import classes from 'classnames';
import styles from './SuccessNotifications.module.scss';
import useNotification from '../../../hooks/useNotification';

type Props = {
  className?: string;
};

export default memo(function SuccessNotifications({ className }: Props) {
  const { notification, removeNotification } = useNotification();
  const location = useLocation();

  useEffect(() => {
    removeNotification();
  }, [location.pathname, removeNotification]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (notification) {
      timeout = setTimeout(() => {
        removeNotification();
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [notification, removeNotification]);

  if (!notification) return null;

  return (
    <Notification
      dataTest="success-notification"
      className={classes(styles.notification, className)}
      type="success"
      onClose={removeNotification}
    >
      {notification.message}
    </Notification>
  );
});
