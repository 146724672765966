import { ClientConstraints } from '../../hooks/webapi/useClientConstraints';
import { memo, useCallback } from 'react';
import classes from 'classnames';
import Slider from '../Slider/Slider';
import styles from './Calculator.module.scss';
import Swiper from '../Swiper/Swiper';
import translate from '../../utils/translate';
import useBreakpoint from '../../hooks/useBreakpoint';

export type CalculatorProps = {
  onTermChange: (value: number) => void;
  constraints: ClientConstraints;
  calculatorAmount?: number;
  calculatorTerm?: number;
  onAmountChange?: (value: number) => void;
  dataTest?: string;
  termEnabled?: boolean;
};

const Calculator = memo(function Calculator({
  onAmountChange,
  onTermChange,
  constraints,
  calculatorAmount,
  calculatorTerm,
  dataTest,
  termEnabled = true,
}: CalculatorProps) {
  const { size } = useBreakpoint();

  const handleAmountChange = useCallback(
    (amount: number) => {
      const {max, min } = constraints.amountInterval;
      if(amount <= max && amount >= min) {
        onAmountChange?.(amount);
      }
    },
    [constraints.amountInterval, onAmountChange],
  );

  return (
    <div data-test={dataTest} className={styles['calculator-wrapper']}>
      <Slider
        dataTest="amount-slider"
        min={constraints.amountInterval.min}
        max={constraints.amountInterval.max}
        step={constraints.amountInterval.step}
        value={
          calculatorAmount !== undefined
            ? calculatorAmount
            : constraints.amountInterval.defaultValue
        }
        onAfterChange={handleAmountChange}
        units={translate('common.currency')}
        heading={translate('calculator.amount_heading')}
        className={styles.slider}
      />

      <Swiper
        key={size.name}
        dataTest="amount-swiper"
        min={constraints.amountInterval.min}
        max={constraints.amountInterval.max}
        step={constraints.amountInterval.step}
        defaultValue={
          calculatorAmount !== undefined
            ? calculatorAmount
            : constraints.amountInterval.defaultValue
        }
        onChange={handleAmountChange}
        snap={130}
        activeItemWidth={150}
        heading={translate('calculator.amount_heading')}
        className={styles.swiper}
      />

      {termEnabled && (
        <>
          <Slider
            dataTest="term-slider"
            min={constraints.termInterval.min}
            max={constraints.termInterval.max}
            step={constraints.termInterval.step}
            value={
              calculatorTerm !== undefined
                ? calculatorTerm
                : constraints.termInterval.defaultValue
            }
            onAfterChange={onTermChange}
            units={translate('calculator.term_unit')}
            heading={translate('calculator.term_heading')}
            className={classes(styles.slider, styles['term-slider'])}
          />

          <Swiper
            key={size.name}
            dataTest="term-swiper"
            min={constraints.termInterval.min}
            max={constraints.termInterval.max}
            step={constraints.termInterval.step}
            defaultValue={
              calculatorTerm !== undefined
                ? calculatorTerm
                : constraints.termInterval.defaultValue
            }
            onChange={onTermChange}
            snap={60}
            heading={translate('calculator.term_heading')}
            className={styles.swiper}
            unitPosition="bottom"
          />
        </>
      )}
    </div>
  );
});

export default Calculator;
