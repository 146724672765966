import { Button, Card, Heading, Paragraph } from '..';
import { memo, ReactNode, useMemo } from 'react';
import AttentionLottie from '../../assets/lottie/attention.json';
import classes from 'classnames';
import DoneLottie from '../../assets/lottie/done.json';
import Lottie from 'react-lottie';
import styles from './StatusCard.module.scss';
import translate from '../../utils/translate';

export type Props = {
  type?: 'success' | 'rejected';
  heading?: string;
  description?: string;
  children?: ReactNode;
  onClick?: () => void;
  button?: string;
  isButtonPending?: boolean;
  className?: string;
  dataTest?: string;
  hideButton?: boolean;
  hasChildrenBeforeButton?: boolean;
  disabled? :boolean;
};

export default memo(function ({
  type = 'success',
  heading,
  children,
  description = '',
  button = translate('common.understood'),
  isButtonPending = false,
  onClick,
  className,
  dataTest,
  hideButton = false,
  hasChildrenBeforeButton = false,
  disabled = false,
}: Props) {
  const lottieOptions = useMemo(
    () => ({
      loop: false,
      autoplay: true,
      animationData: type === 'success' ? DoneLottie : AttentionLottie,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }),
    [type],
  );

  const cardStyle = type === 'success' ? styles['card-success'] : styles.card;

  return (
    <Card className={classes(cardStyle, className)}>
      <div className={styles.content}>
        <div className={styles['lottie-wrapper']}>
          <Lottie options={lottieOptions} />
        </div>
        {heading && (
          <Heading className={styles.heading} size="l">
            <span dangerouslySetInnerHTML={{ __html: heading }} />
          </Heading>
        )}

        {description && (
          <Paragraph className={styles.description}>
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </Paragraph>
        )}
      </div>
      {hasChildrenBeforeButton && children}
      {onClick && !hideButton && (
        <Button
          dataTest="continue"
          className={styles.button}
          onClick={onClick}
          pending={isButtonPending}
          disabled={disabled}
        >
          {button}
        </Button>
      )}
      {!hasChildrenBeforeButton && children}
    </Card>
  );
});
