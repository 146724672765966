import * as R from '../../Router/routes';
import { useEffect, useState } from 'react';

import { Card, MultiField, Notification } from '../../components';
import { datadogLogs } from '@datadog/browser-logs';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-mono.svg';
import { useAuth, useClient, useLatestLoan } from '../../hooks/webapi';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import classNames from 'classnames';
import Flow from '../../components/Layouts/Flow/Flow';
import Heading from '../../components/Heading/Heading';
import HelpBlock from '../../components/HelpBlock/HelpBlock';
import Link from '../../components/Link/Link';
import styles from './TwoFA.module.scss';
import translate from '../../utils/translate';

type LoginFormFields = { verificationCode: string[] };

type LocationsState = {
  encodedCredentials: string;
};

const codeRegex = RegExp(/^[0-9]{4}$/);

const validate = (values: LoginFormFields) => {
  const errors: FormikErrors<LoginFormFields> = {};

  const joinedCode = values.verificationCode.join('');
  if (!joinedCode || !joinedCode.match(codeRegex)) {
    errors.verificationCode = translate('error.validation.empty');
  }

  const hasAnyError = Object.values(errors).filter(Boolean).length > 0;

  return hasAnyError ? errors : {};
};

export default function TwoFA() {
  const [timeLeft, setTimeLeft] = useState(60);
  const [resendIsDisabled, setResendIsDisabled] = useState(true);
  const navigate = useNavigate();
  const [pending, setPending] = useState(false);
  const { postAccessVerification, resend, crossLoginRedirect } = useAuth();
  const { fetchClient, postAnalyticsCookies } = useClient();
  const { fetchLatestLoan } = useLatestLoan();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const [serverError, setServerError] = useState('');

  useEffect(() => {
    if (!resendIsDisabled) {
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft((timeLeft) => timeLeft - 1);
    }, 1000);

    if (timeLeft === 0) {
      setResendIsDisabled(false);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [resendIsDisabled, timeLeft]);

  const handleResendClick = async () => {
    setTimeLeft(60);
    setResendIsDisabled(true);
    resend((location.state as LocationsState).encodedCredentials);
  };

  const getClearQueryString = () => {
    urlParams.delete('sessionExpired');
    urlParams.delete('redirect');

    const paramsString = urlParams.toString();

    if (paramsString) {
      return decodeURIComponent(`?${paramsString}`);
    }

    return '';
  };

  const handleSubmit = async (
    values: LoginFormFields,
    actions: FormikHelpers<LoginFormFields>,
  ) => {
    try {
      if (!resendIsDisabled) {
        return;
      }

      setPending(true);
      const response = await postAccessVerification(
        values.verificationCode.join(''),
        (location.state as LocationsState).encodedCredentials,
      );

      const [client, latestLoan] = await Promise.all([
        fetchClient(),
        fetchLatestLoan(),
        postAnalyticsCookies(),
      ]);

      const redirect = urlParams.get('redirect')
        ? decodeURIComponent(`${urlParams.get('redirect')}`)
        : undefined;

      if (response.fromTemporaryPassword) {
        navigate(R.PASSWORD_RECOVERY_NEW_PASSWORD);
      } else if (client && client.status === 'IDENTIFIED') {
        if (redirect) {
          navigate(`${redirect}${getClearQueryString()}`);
        } else if (latestLoan) {
          navigate(`${R.ACTIVE_LOAN}${getClearQueryString()}`);
        } else {
          navigate(`${R.REPEATED_LOAN}${getClearQueryString()}`);
        }
      } else if (client && client.status === 'REGISTERED') {
        await crossLoginRedirect({ invalidateSession: true });
      } else {
        navigate(`${R.ACTIVE_LOAN}${getClearQueryString()}`);
      }
    } catch (error) {
      datadogLogs.logger.error(`2FA FAILED`, {
        error,
      });

      if (error instanceof Error) {
        setServerError(error.message);
      } else {
        setServerError(translate('error.generic'));
      }

      setPending(false);
    }
  };

  const formik = useFormik<LoginFormFields>({
    initialValues: {
      verificationCode: [],
    },
    onSubmit: handleSubmit,
    validate,
  });

  const handleChange = async (
    fieldName: string,
    values: Array<string | undefined>,
  ) => {
    await formik.setFieldValue(fieldName, values);
  };

  const backUrl = `${R.LOGIN}?from2fa=true`;

  return (
    <Flow backUrl={backUrl} withMenu={false} className={styles.layout}>
      <Helmet>
        <title>{translate('twoFa.html_title')}</title>
      </Helmet>

      {serverError && (
        <div className={styles.notification}>
          <Notification
            dataTest="server-error"
            type="error"
            onClose={() => setServerError('')}
          >
            {serverError}
          </Notification>
        </div>
      )}

      <Heading size="l" className={styles.heading}>
        {translate('twoFa.title')}
      </Heading>
      <div className={styles.subheading}>
        <p>{translate('twoFa.subtitle')}</p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles['input-container']}>
          <MultiField
            dataTest="2fa"
            name="verificationCode"
            value={formik.values.verificationCode}
            onChange={handleChange}
            error={formik.errors.verificationCode as any}
            isTouched={formik.touched.verificationCode as boolean}
          />
        </div>
        <Link
          onClick={handleResendClick}
          className={classNames(
            styles.link,
            resendIsDisabled && styles.disabled,
          )}
          disabled={resendIsDisabled}
        >
          {translate('twoFa.resend')} {resendIsDisabled && `(${timeLeft}s)`}
        </Link>
        <Button
          className={styles.submit}
          pending={pending}
          type="submit"
          disabled={pending}
          dataTest="submit-button"
        >
          {translate('twoFa.submit')}
        </Button>
      </form>
      <div className={styles['have-questions']}>
        <HelpBlock
          textAlignment="center"
          showIcon={false}
          description={translate('twoFa.contact_details')}
          className={styles['no-margin-top']}
        />
      </div>
      <Card className={styles['info-card']}>
        <div className={styles['info-card-icon']}>
          <InfoIcon />
        </div>
        <div className={styles['info-card-content']}>
          {translate('twoFa.info')}
        </div>
      </Card>
    </Flow>
  );
}
