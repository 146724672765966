import { ReactComponent as ExternalIcon } from '../../assets/icons/external.svg';
import { Link } from 'react-router-dom';
import classes from 'classnames';
import Loader from './Loader/Loader';
import React, { memo, ReactNode } from 'react';
import styles from './Button.module.scss';

export type Props = {
  type?: 'submit' | 'button';
  children: ReactNode;
  outline?: boolean;
  disabled?: boolean;
  className?: string;
  dataTest?: string;
  wide?: boolean;
  pending?: boolean;
  size?: 's' | 'm' | 'l';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (
    event?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
  ) => void;
  /* When given, makes button act as link */
  to?: string;
  /* Used when `to` is passed. Determines whether to use inner router or link to external site */
  external?: boolean;
  buttonClassName?: string;
};

export default memo(function Button({
  children,
  type = 'button',
  outline = false,
  disabled = false,
  wide = false,
  pending = false,
  className,
  dataTest,
  size = 'l',
  onClick,
  onMouseEnter,
  to = '',
  external,
  buttonClassName,
}: Props) {
  const buttonStyle = classes(
    styles.button,
    outline && styles.outline,
    pending && styles.pending,
    styles[size],
    buttonClassName,
    wide && styles.wide,
  );

  return (
    <div
      className={classes(
        styles.container,
        styles[size],
        wide && styles.wide,
        className,
      )}
    >
      {to && !external && (
        <Link
          className={buttonStyle}
          onMouseEnter={onMouseEnter}
          data-test={dataTest}
          to={to}
        >
          {!(size === 's' && pending) && children}
          {pending && (
            <div className={styles[`loader-wrapper-${size}`]}>
              <Loader outline={outline} />
            </div>
          )}
        </Link>
      )}
      {to && external && (
        <a
          className={buttonStyle}
          onMouseEnter={onMouseEnter}
          data-test={dataTest}
          href={to}
          rel="noopener"
        >
          {!(size === 's' && pending) && children}
          {external && <ExternalIcon className={styles['external-icon']} />}
          {pending && (
            <div className={styles[`loader-wrapper`]}>
              <Loader outline={outline} />
            </div>
          )}
        </a>
      )}
      {!to && (
        <button
          data-test={dataTest}
          disabled={disabled}
          type={type}
          onClick={onClick}
          className={buttonStyle}
          onMouseEnter={onMouseEnter}
        >
          {!(size === 's' && pending) && children}
          {pending && (
            <div className={styles[`loader-wrapper`]}>
              <Loader outline={outline} />
            </div>
          )}
        </button>
      )}
    </div>
  );
});
