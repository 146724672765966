import { memo, ReactNode, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { translate } from '../../../../utils';
import classes from 'classnames';
import Heading from '../../../Heading/Heading';
import Link from '../../../Link/Link';
import Paragraph from '../../../Paragraph/Paragraph';
import styles from './MobileMenu.module.scss';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { LoadableComponentMethods } from '@loadable/component';
import { ReactComponent as LogoutIcon } from '../../../../assets/icons/logout.svg';

export type MenuItem = {
  title: string;
  url: string;
  additionalActiveState?: string[];
  icon?: ReactNode;
  disabled?: boolean;
  dataTest: string;
  preload?: LoadableComponentMethods<unknown>['preload'];
};

export type Props = {
  className?: string;
  clientName?: string;
  clientContract?: string;
  active: string;
  onSelect?: (url: string) => void;
  menuItems: MenuItem[];
  onClose?: () => void;
  onLogout?: () => void;
  pending?: boolean;
};

export default memo(function MobileMenu({
  className,
  active,
  clientName,
  clientContract,
  onSelect,
  menuItems,
  onClose,
  onLogout,
  pending,
}: Props) {
  useEffect(() => {
    menuItems.forEach((item) => item.preload?.());
  }, [menuItems]);

  // Prevent main body from scrolling when menu is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  });

  const handleClick = (url: string) => {
    if (onSelect) {
      onSelect(url);
    }
  };

  return (
    <div className={classes(styles.container, className)}>
      {onClose && (
        <div className={classes(styles.close)}>
          <CloseIcon onClick={onClose} className={styles['close-icon']} />
        </div>
      )}
      <div className={styles['scroll-box']}>
        <Heading className={styles.heading}>{clientName}</Heading>
        {clientContract && (
          <Paragraph type="small" className={styles.contract}>
            {translate('menu.contract', { clientContract })}
          </Paragraph>
        )}
        <div className={styles.menu}>
          {menuItems.map((menu, index) => (
            <RouterLink
              data-test={`mobile-menu-${menu.dataTest}`}
              className={classes(
                styles.item,
                (active === menu.url ||
                  menu.additionalActiveState?.includes(active)) &&
                  styles.selected,
                menu.disabled && styles.disabled,
              )}
              to={menu.url}
              onClick={() => handleClick(menu.url)}
              key={`${menu.url}-${index}`}
            >
              <div
                className={classes(
                  styles.icon,
                  (active === menu.url ||
                    menu.additionalActiveState?.includes(active)) &&
                    styles['selected-icon'],
                )}
              >
                {menu.icon}
              </div>
              <p className={styles.title}>{menu.title}</p>
            </RouterLink>
          ))}
        </div>
        <div className={styles['logout-container']}>
          <Link disabled={pending} onClick={onLogout} className={styles.logout}>
            <div>{translate('menu.logout')}</div>
            <LogoutIcon />
          </Link>
        </div>
      </div>
    </div>
  );
});
