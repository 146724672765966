import { ReactComponent as EnvelopeIcon } from '../../../../assets/icons/envelope.svg';
import { memo } from 'react';
import { ReactComponent as PhoneIcon } from '../../../../assets/icons/phone.svg';
import classes from 'classnames';
import isNowHoliday from '../../../../utils/isNowHoliday';
import Link from '../../../Link/Link';
import Paragraph from '../../../Paragraph/Paragraph';
import styles from './Footer.module.scss';
import translate from '../../../../utils/translate';

export type Props = {
  className?: string;
  dataTest?: string;
};

const links = {
  terms_and_conditions:
    'https://www.zaplo.cz/obchodni-podminky/smlouva-o-uveru',
  privacy_policy:
    'https://www.zaplo.cz/obchodni-podminky/ochrana-osobnich-udaju',
};

export default memo(function Footer(props: Props) {
  return (
    <footer
      data-test={props.dataTest}
      className={classes(styles.container, props.className)}
    >
      <div className={styles.left}>
        <div className={styles.contacts}>
          <div className={styles.contact}>
            <EnvelopeIcon className={styles.envelope} />
            <a
              data-test="footer-mail"
              href={`mailto:${translate('common.email')}`}
              className={styles.link}
            >
              {translate('common.email')}
            </a>
          </div>
          <div className={styles.contact}>
            <PhoneIcon className={styles.phone} />
            <a
              data-test="footer-number"
              href={`tel:${translate('common.phone_link')}`}
              className={styles.link}
            >
              {translate('common.phone')}
            </a>
          </div>
        </div>
        <div className={styles['work-hours-container']}>
          <Paragraph className={styles['work-hours']} type="small">
            <span
              dangerouslySetInnerHTML={{
                __html: isNowHoliday()
                  ? translate('footer.work_hours_holidays')
                  : translate('footer.work_hours'),
              }}
            />
          </Paragraph>
        </div>
      </div>
      <div className={styles['small-links']}>
        <Link
          to={links.terms_and_conditions}
          external
          size="s"
          className={styles['small-link']}
        >
          {translate('footer.terms_and_conditions')}
        </Link>
        <Link
          to={links.privacy_policy}
          external
          size="s"
          className={styles['small-link']}
        >
          {translate('footer.privacy_policy')}
        </Link>
      </div>
    </footer>
  );
});
