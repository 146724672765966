import { ReactComponent as HandleIcon } from '../../assets/icons/vertical-hamburger.svg';
import { memo, useCallback, useState } from 'react';
import classes from 'classnames';
import ReactSlider from 'react-slider';
import styles from './Slider.module.scss';

export type Props = {
  min: number;
  max: number;
  value?: number;
  heading?: string;
  step?: number;
  units?: string;
  onChange?: (value: any) => void;
  onAfterChange: (value: any) => void;
  dataTest?: string;
  className?: string;
};

type Thumb = {
  valueNow: number;
};

const Slider = memo(function Slider({
  min,
  max,
  value,
  heading,
  step,
  units,
  onChange,
  onAfterChange,
  dataTest,
  className,
}: Props) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const showTooltip = useCallback(() => {
    setIsTooltipVisible(true);

    // Hide tooltip when mosue released anywhere on the page
    document.addEventListener(
      'mouseup',
      () => {
        setIsTooltipVisible(false);
      },
      { once: true },
    );
  }, []);

  const renderThumb = useCallback(
    (props: Object, state: Thumb) => {
      const { valueNow } = state;

      return (
        <div {...props}>
          <div onMouseDown={showTooltip} className={styles.handle}>
            <HandleIcon className={styles['handle-icon']} />
            {isTooltipVisible && (
              <div className={classes(styles.tooltip)}>
                <div className={styles.amount}>
                  <span data-test="thumb" className={styles.value}>
                    {valueNow}
                  </span>
                  {units && <span className={styles.units}>{units}</span>}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    },
    [showTooltip, isTooltipVisible, units],
  );

  return (
    <div data-test={dataTest} className={className}>
      <div className={styles.labels}>
        <span className={styles.heading}>{heading}</span>
        <span className={styles['amount-label']}>
          {value}
          {units && <span className={styles.units}> {units}</span>}
        </span>
      </div>
      <ReactSlider
        className={styles.slider}
        min={min}
        max={max}
        step={step}
        value={value}
        thumbClassName={styles.thumb}
        trackClassName={styles.track}
        renderThumb={renderThumb}
        onChange={onChange}
        onAfterChange={onAfterChange}
      />
      <div className={styles.limits}>
        <span>
          {min} {units}
        </span>
        <span>
          {max} {units}
        </span>
      </div>
    </div>
  );
});

export default Slider;
