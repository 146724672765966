import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

export type ClientHousehold = {
  numberOfInhabitants: number;
  numberOfAdults: number;
  ownership:
    | 'MORTGAGE'
    | 'OWNED'
    | 'COLLECTIVE'
    | 'RENT'
    | 'WITH_RELATIVES'
    | 'EMPLOYER'
    | 'DORMITORY_OR_HOTEL';
};

export type PostClientHouseholdRequest = {
  body: ClientHousehold;
};

type ClientHouseholdContextType = {
  clientHousehold?: ClientHousehold;
  fetchClientHousehold: () => Promise<ClientHousehold | void>;
  updateHouseHold: ({
    body,
  }: PostClientHouseholdRequest) => Promise<ClientHousehold | void>;
};

const ClientHouseholdContext = createContext<ClientHouseholdContextType>(
  {} as ClientHouseholdContextType,
);

const useClientHousehold = (): ClientHouseholdContextType =>
  useContext(ClientHouseholdContext);

const ClientHouseholdProvider = ({ children }: Props): JSX.Element => {
  const [clientHousehold, setClientHousehold] = useState<ClientHousehold>();
  const { showError } = useError();

  const fetchClientHousehold =
    useCallback(async (): Promise<ClientHousehold | void> => {
      try {
        const fetchedClientHousehold = await fetchData(`/client/household`, {
          headers: {
            Accept: 'application/json',
          },
        });

        setClientHousehold(fetchedClientHousehold);

        return fetchedClientHousehold;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const updateHouseHold: ClientHouseholdContextType['updateHouseHold'] =
    useCallback(
      async ({ body }) => {
        try {
          const resultClientHousehold = await fetchData(`/client/household`, {
            method: 'put',
            headers: {
              Accept: 'application/json',
            },
            body: JSON.stringify(body),
          });

          setClientHousehold(resultClientHousehold);
          return resultClientHousehold;
        } catch (e) {
          showError();
          throw e;
        }
      },
      [showError],
    );

  const clientHouseholdContextValue = useMemo(
    () => ({
      clientHousehold,
      fetchClientHousehold,
      updateHouseHold,
    }),
    [clientHousehold, fetchClientHousehold, updateHouseHold],
  );

  return (
    <ClientHouseholdContext.Provider value={clientHouseholdContextValue}>
      {children}
    </ClientHouseholdContext.Provider>
  );
};

export { useClientHousehold as default, ClientHouseholdProvider };
