import { fetchData } from '../../utils';
import { useCallback, useMemo } from 'react';

type CreateTemporaryPasswordPayload = {
  username: string;
};

const usePasswordRecovery = () => {
  /** Sends a temporary password to client's email */
  const createTemporaryPassword = useCallback(
    async (body: CreateTemporaryPasswordPayload, recaptchaToken) => {
      try {
        await fetchData(
          '/client/temporary-password',
          {
            method: 'POST',
            body: JSON.stringify(body),
          },
          undefined,
          { responseToken: recaptchaToken },
        );
      } catch (e) {
        throw e;
      }
    },
    [],
  );

  return useMemo(
    () => ({
      createTemporaryPassword,
    }),
    [createTemporaryPassword],
  );
};

export default usePasswordRecovery;
