import { fetchData } from '../../utils';
import { useCallback, useMemo } from 'react';
import useError from '../useError';

const useChangePassword = () => {
  const { showError } = useError();

  const changePassword = useCallback(
    async ({ body }) => {
      try {
        const data = await fetchData('/client/password', {
          method: 'put',
          body: JSON.stringify(body),
        });

        return data;
      } catch (e) {
        if (e.name !== 'ValidationError') {
          showError();
        }
        throw e;
      }
    },
    [showError],
  );

  return useMemo(() => {
    return {
      changePassword,
    };
  }, [changePassword]);
};

export default useChangePassword;
