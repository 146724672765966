import { memo, ReactNode } from 'react';
import classes from 'classnames';
import styles from './Heading.module.scss';

export type Props = {
  children: ReactNode;
  className?: string;
  dataTest?: string;
  size?: 's' | 'm' | 'l';
};

export default memo(function Heading({
  children,
  className,
  dataTest,
  size = 'm',
}: Props) {
  let Text: 'h1' | 'h2' | 'h3';

  if (size === 'l') {
    Text = 'h1';
  } else if (size === 'm') {
    Text = 'h2';
  } else {
    Text = 'h3';
  }

  return (
    <Text
      className={classes(styles.heading, styles[size], className)}
      data-test={dataTest}
    >
      {children}
    </Text>
  );
});
