import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type Props = {
  children: ReactNode;
};

export type Notification = {
  message?: ReactNode;
};

export type NotificationContextType = {
  notification?: Notification;
  showNotification: (message: Notification) => void;
  removeNotification: () => void;
};

const NotificationContext = createContext<NotificationContextType>(
  {} as NotificationContextType,
);

const useNotification = (): NotificationContextType =>
  useContext(NotificationContext);
const NotificationProvider = ({ children }: Props): JSX.Element => {
  const [notification, setNotification] = useState<Notification>();

  const showNotification = useCallback((message: Notification = {}) => {
    setNotification(message);
  }, []);

  const removeNotification = useCallback(() => {
    setNotification(undefined);
  }, []);

  const notificationContextValue = useMemo(
    () => ({
      notification,
      showNotification,
      removeNotification,
    }),
    [notification, showNotification, removeNotification],
  );
  return (
    <NotificationContext.Provider value={notificationContextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export { useNotification as default, NotificationProvider };
