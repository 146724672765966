const cz = {
  common: {
    currency: 'Kč',
    email: 'info@zaplo.cz',
    phone: '800 399 399',
    phone_link: '800399399',
    back: 'Zpět',
    days: '{days, plural, =1 [den] =2 [dny] =3 [dny] =4 [dny] other [dní]}',
    days_amount:
      '{days, plural, =1 [# den] =2 [# dny] =3 [# dny] =4 [dny!] other [# dní]}',
    save: 'Uložit',
    greeting: 'Dobrý den, {name}',
  },

  error: {
    validation: {
      file_size_too_big:
        'Soubor je příliš velký, maximální velikost je {placeholder}',
      file_format_invalid:
        'Aktuální formát dokumentu není podporován, zkuste jiný.',
      already_exists_in_db:
        'Pokud již jste zákazníkem, zadejte svou zákaznickou oblast.',
      empty_email: 'Je vyžadován e-mail.',
      empty: 'Toto pole je povinné.',
      forbidden:
        'Vyskytl se problém.\n Kontaktujte nás prosím telefonicky <a href="tel:912907788">91 290 77 88</a>.',
      general_error:
        'Nastala neznámá chyba. Kontaktujte nás prosím.\n Došlo k neznámé chybě.',
      incorrect_password: 'Nesprávné heslo.',
      login_unavailable:
        'Vyskytl se problém.<br />Kontaktujte nás prosím telefonicky <a href="tel:912907788">91 290 77 88</a>.',
      not_email: 'Nesprávný formát e-mailu.',
      password_contains_space: 'Znak mezery není povolen',
      password_is_same_as_old: 'Nové heslo se musí lišit od starého.',
      restrict_diacritics_chars: 'Písmená s diakritikou nejsou povolené',
      restrict_special_chars: 'Speciální znaky jako (!@#$...) nejsou povoleny',
      service_unavailable:
        'Vyskytl se problém.\n Omlouváme se za nepříjemnosti, měli jsme technický problém. Zkuste to prosím znovu později nebo nás kontaktujte na telefonním čísle <strong><a href="tel:912907788">91 290 77 88</a>.</strong>',
      unauthorized:
        'Nesprávný e-mail nebo heslo. \nZkontrolujte prosím své údaje a zkuste to znovu.',
      weak_password: 'Je vyžadováno alespoň jedno číslo.',
      not_valid:
        'Přidružený účet pro tento e-mail nebyl nalezen. Zkuste prosím jinou adresu.',
      not_equal: 'Hesla se neshodují.',
      same_as_tmp_password: 'Nové heslo stejné jako dočasné heslo.',
      invalid_phone_number: 'Telefonní číslo je neplatné',
      incorrect_code: 'Ověřovací kód je neplatný',
      min_length: 'Musí obsahovat nejméně {length} znaky',
      min_length_with_subject:
        '{subject} musí obsahovat nejméně {length} znaky',
      max_length: 'Nesmí přesáhnout {length} znaků',
      max_length_with_subject: '{subject} nesmí přesáhnout {length} znaků',
      has_alphanumeric_chars_with_subject:
        '{subject} musí obsahovat písmena i číslice',
      has_alphanumeric_chars: 'Musí obsahovat písmena i číslice',
      out_of_range: 'Nevešli jste se do limitu',
      household_members_toosmall:
        'Číslo musí být větší nebo rovno počtu členů domácnosti s příjmem',
      household_members_maxamount: 'Maximální počet členů domácnosti je 20.',
      household_employedmembers_toobig:
        'Číslo musí být menší nebo rovno počtu členů domácnosti',
      household_employedmembers_maxamount:
        'Maximální počet členů domácnosti s příjmem je 20.',
      expenses_bigger_than_income:
        'Náklady nemohou být vyšší než měsíční příjem.',
      income_smaller_than_expenses:
        'Příjmy nemohou být nižší než měsíční výdaje.',
      expenses_too_low: 'Měsíční výdaje jsou příliš nízké',
      too_many_attempts:
        'Bylo učiněno příliš mnoho pokusů. Zavolejte prosím na číslo 800 399 399 pro dokončení vašeho požadavku',
    },
    generic: 'Tato stránka není momentálně dostupná.',
    token_expired: 'Platnost tokenu vypršela',
  },

  menu: {
    logout: 'Odhlásit',
    contract: 'Číslo půjčky: {clientContract}',
    active_loan: 'Aktuální půjčka',
    new_loan: 'Nová půjčka',
    additional_amount: 'Možnost navýšení',
    extension: 'Potřebujete více času?',
    profile: 'Můj profil',
  },

  footer: {
    work_hours: 'Po — Pá / 8:00 — 19:00',
    work_hours_holidays: 'Po — Pá / 8:00 — 18:00',
    terms_and_conditions: 'Smluvní podmínky',
    privacy_policy: 'Ochrana osobních údajů',
  },

  desktop_header: {
    back: 'Zpět',
    logout: 'Odhlásit',
  },

  active_loan: {
    html_title: 'Aktuální půjčka - Zaplo.cz',
    amount: 'Aktuální půjčka',
    pay: 'Zaplatit',
    repayment_date: 'Informace o půjčce',
    contract_number: 'Smlouva č.: {contract}',
    additional_amount_title: 'Potřebujete více peněz?',
    additional_amount_text_disabled:
      'Je nám líto, navýšení půjčky v tuto chvíli není možné.',
    additional_amount_text:
      'Stačí jednoduše vybrat částku a kliknout na Potvrdit navýšení.',
    extension_title: 'Potřebujete více času?',
    extension_text:
      'Nabízíme Vám možnost prodloužit splatnost Vaší půjčky do 30 dnů od původního data splatnosti.',
    extension_text_disabled:
      'Je nám líto, prodloužení splatnosti půjčky v tuto chvíli není možné.',
    due_date: 'do splatnosti {date}',
    loan_information: 'Informace o půjčce',
    banner_discount_text: 'Máte slevu',
    now: 'Dnes',
    now_description: 'je den spaceni pujcky',
    active_debit_title:
      'Aktivní splátkový kalendář ke smlouvě o úvěru číslo: {loanNumber}',
    active_debit_text:
      'Informace k potvrzení splátkového kalendáře, spolu s přehledem splátek Vám byla zaslána na Vaši emailovou adresu. V případě dotazů, kontaktuje oddělení správy pohledávek na tel: <a href="tel:800999966">800 999 966</a> nebo emailem na <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>',
  },

  overdue_loan: {
    amount: 'Aktuální dlužná částka',
    pay: 'Zaplatit',
    daysPastDue1:
      'Vaše půjčka je po splatnosti {days, plural, =1 [# den] =2 [# dny] =3 [# dny] =4 [# dny] other [# dní]}. Aktuální dlužná částka je {amount} Kč. V případě problémů se splácením, kontaktuje naši bezplatnou zákaznickou linku',
    daysPastDue2:
      'Stále si ještě lze prodloužit splatnost. Musíme vás však upozornit, že jsme nuceni evidovat Váš nezaplacený dluh v Nebankovním registru klientských informací. I přes opakované výzvy nedošlo k úhradě celé dlužné částky a jsme proto nuceni účtovat vám sankce. V případě problémů se splácením, kontaktuje naši bezplatnou zákaznickou linku',
    daysPastDue3:
      'Upozorňujeme vás, že Váš dluh je již evidován v Nebankovním registru klientských informací. Protože ani přes opakované výzvy nedošlo k úhradě celé dlužné částky, účtujeme vám sankce. Následně bude pohledávka předána do správy inkasních agentur. V případě problémů se splácením, kontaktuje naši bezplatnou zákaznickou linku',
  },

  overdue_loan_detail: {
    title: 'Vaše půjčka je po splatnosti',
  },

  loan_details: {
    html_title: 'Informace o půjčce - Zaplo.cz',
    heading: 'Informace o půjčce',
    loan_agreement: 'Smlouva o úvěru',
    redemption_table: 'Tabulka umoření',
    due_date: 'Datum splatnosti',
    latest_payment: 'Vaše poslední platba',
    loan_amount: 'Výše půjčky',
    interest: 'Úrok v Kč',
    interest_rate: 'Roční úroková sazba',
    payable_amount: 'Celkem ke splacení',
    extension_title: 'Potřebujete více času?',
    extension_text:
      'Nabízíme Vám možnost prodloužit splatnost Vaší půjčky o 30 dní',
    extension_button: 'Prodloužit nyní',
    repay: 'Zaplatit',
    penalty_amount: 'Smluvní pokuta z prodlení',
    commission_amount: 'Poplatek za upomínku',
  },

  additional_amount: {
    heading: 'Můžete si půjčit dalších {amount} Kč',
    html_title: 'Možnost navýšení',
    heading_mobile: 'Možnost navýšení',
    subheading_mobile: 'Spočítejte si svou půjčku',
    amount_heading: 'Výše půjčky',
    amount_heading_mobile: 'Výše půjčky (KČ)',
    apply: 'Potvrdit navýšení',
  },

  additional_amount_details_table: {
    due_date: 'Datum splatnosti',
    loan_amount: 'Aktuálně ke splacení',
    additional_amount: 'Navýšit o',
    additional_interest: 'Úroky navíc',
    interest_rate: 'Roční úroková sazba',
    total_repayable_amount: 'Po navýšení celkem ke splacení',
  },

  calculator: {
    amount_heading: 'Výše půjčky (Kč)',
    term_heading: 'Délka splatnosti (dnů)',
    currency_amount: '{amount} Kč',
    term_unit: 'dní',
  },

  repeated_loan: {
    html_title: 'Spočítejte si svou půjčku - Zaplo.cz',
    heading: 'Spočítejte si svou půjčku',
    total_to_return: 'Celkem ke splacení',
    repayment_date: 'Datum splatnosti',
    apply: 'Chci půjčku',
    representative_example:
      '{totalPrincipal} na {term} dnů, pevná roční úroková sazba {interestRate}, RPSN {annualPercentageRate}. Celková částka splatná spotřebitelem je {totalRepayableAmount}. Celkové náklady spotřebitelského úvěru jsou {newInterest}. Toto je pouze reprezentativní příklad půjčky, nikoliv návrh na uzavření smlouvy. Zaplo Finance s.r.o. si vyhrazuje právo na posouzení úvěrové žádosti.',
  },

  document_upload: {
    html_title: 'Potvrzení o příjmu',
    heading: 'Potvrzení o příjmu',
    mobile_heading_pending: 'Vyčkejte prosím',
    subheading_mobile:
      'Připravte si, prosím Váš občanský průkaz. Fotografie dokladu pořídíte kliknutím na box občanský průkaz',
    subheading:
      'Nahrajte nám doklad, potvrzující Váš příjem. Akceptujeme potvrzení o příjmu, vypis z bankovního účtu nebo tři vyplatní pásky (ne starší 3 měsíců a to ve formátu: jpg, png nebo pdf).',
    main_doc_heading: 'Potvrzení o příjmu',
    alt_doc_heading: 'Další dokument',
    alt_doc_subheading: 'Volitelný dokument',
    card_heading: 'Nahrát dokument z Vašeho zařízení',
    submit_button: 'POKRAČOVAT',
    skip_link: 'Zaslat později',
    note_heading: 'Vyčkejte prosím, budeme Vás kontaktovat',
    note_subheading: 'Vaše žádost o půjčku je právě vyhodnocována.',
    help_heading: 'Máte otázku? Máte dotaz?',
    proof_of_income_mobile_heading: 'Potvrzení o příjmu',
    help_info:
      'Kontaktujte naši zákaznickou linku na<br><a href="tel:800399399">800 399 399</a>  nebo emailem  <a href="mailto:info@zaplo.cz">info@zaplo.cz</a> <div>Po — Pá / 8:00 — 22:00   So — Ne / 8:00 — 20:00</div>',
    help_info_holidays:
      'Kontaktujte naši zákaznickou linku na<br><a href="tel:800399399">800 399 399</a>  nebo emailem  <a href="mailto:info@zaplo.cz">info@zaplo.cz</a> <div>Po — Pá / 8:00 — 18:00</div>',
  },

  kyc_upload: {
    html_title: 'Ověření totožnosti',
    heading: 'Ověření totožnosti',
    subheading:
      'Nahrajte nám Váš občanský průkaz. Fotografii dokladu nahrajte kliknutím na box občanský průkaz.',
    card_heading: 'Nahrát dokument z Vašeho zařízení',
    frontSide_heading: 'Občanský průkaz',
    frontSide_subheading: '(přední strana)',
    backSide_heading: 'Občanský průkaz',
    backSide_subheading: '(zadní strana)',
  },

  new_offer_increased: {
    html_title: 'Máme pro Vás dobrou zprávu - Zaplo.cz',
    heading: 'Máme pro vás<br />dobrou zprávu',
    mobile_heading: 'Gratulujeme!',
    message: 'Můžete si půjčit až',
    btn: 'Navýšit částku',
    original_amount_btn: 'Pokračovat s původní částkou',
  },

  loan_budget: {
    html_title: 'Prosím zkontrolujte si níže uvedené informace - Zaplo.cz',
    heading: 'Prosím zkontrolujte si níže\nuvedené informace',
    heading_mobile: 'Prosím zkontrolujte',
    monthly_income: 'Čistý měsíční příjem (po zdanění)',
    monthly_expenses: 'Měsíční výdaje',
    household_members: 'Celkový počet členů Vaší domácnosti',
    employed_household_members:
      'Celkový počet členů Vaší domácností s pravidelným příjmem',
    housing_type: 'Druh bydlení',
    btn: 'Pokračovat',
    consent_heading:
      'Pro možnost čerpání našich služeb v plném rozsahu Vás žádáme i o udělení tohoto souhlasu:',
    ownership_types: {
      mortgage: 'Hypotéka',
      owned: 'Ve vlastnictví',
      collective: 'Kolektivní',
      rent: 'Nájem',
      with_relatives: 'S příbuznými',
      employer: 'Zaměstnavatel',
      dormitory_or_hotel: 'Ubytovna / hotel',
    },
  },

  loan_rejected: {
    html_title: 'Půjčka zamítnuta - Zaplo.cz',
    heading: 'V tuto chvíli Vám půjčku nemůžeme poskytnout',
    mobile_heading: 'Je nám líto',
    message: {
      invalid_documents:
        'Je nám líto, ale Vaše půjčka byla zamítnuta {resolutionDetailMessage}.',
      general:
        'Je nám líto, ale Vaše půjčka byla zamítnuta na základě interního vyhodnocení',
    },
    btn: 'Zpět na hlavní stránku',
  },
  loan_confirm: {
    heading: 'Potvrzení výše půjčky a termínu splatnosti',
    mobile_heading: 'Podepsání smlouvy',
    loan_amount: 'Výše půjčky',
    payment_amount: 'Ke splacení',
    due_date: 'Datum splatnosti',
    edit: 'Změnit',
    interest_in_czk: 'Úrok v Kč',
    interest: 'Roční úroková sazba',
    total: 'Celkem ke splacení',
    consent: {
      contract_info_1: 'Četl(a) jsem tyto ',
      contract_info_link: 'předsmluvní informace',
      contract_info_2: ', byly mi vysvětleny a rozumím jim.',
      loan_contract_1:
        'Potvrzuji tímto, že jsem přečetl(a) a jsem srozuměn(a) s obsahem ',
      loan_contract_link: 'smlouvy o úvěru',
      loan_contract_2:
        ' a souvisejícími obchodními podmínkami a chci jí uzavřít.',
      approve: 'Souhlasím',
      confirm: 'Potvrdit',
      download: 'Stáhnout',
    },
    contract_sign: {
      heading: 'Podepsání smlouvy',
      text: 'Pro potvrzení této smlouvy o úvěru prosíme zadejte kód zaslaný SMS na Vaše telefonní číslo ',
      code: 'Potvrzovací kód',
      footer:
        'Může chvíli trvat, než Vám SMS dorazí. Pokud jste SMS neobdrželi, tak pro dokončení žádosti kontaktujte naši zákaznickou linku na <a href="tel:800399399">800 399 399</a> nebo emailem <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>',
      button: 'Podepsat a potvrdit',
      resend: 'Znovu zaslat kód',
      invalid_sms: 'Ověřovací kód je neplatný',
    },
    contract_info: 'Formulář pro standardní informace o spotřebitelském úvěru',
    loan_contract: 'Smlouva o úvěru',
    continue: 'Pokračovat',
    wait: 'Počkejte prosím',
  },

  loan_approved: {
    html_title: 'Půjčka schválena - Zaplo.cz',
    heading: 'Vaše žádost o půjčku byla schválena',
    mobile_heading: 'Gratulujeme!',
    subheading:
      'Peníze odesíláme ihned nebo vždy nejbližší pracovní den. Smlouvu naleznete v sekci informace o půjčce',
    btn: 'Zákaznický účet',
  },

  personal_data_confirm: {
    heading: 'Osobní údaje',
    title: 'Zkontrolujte si, zda jsou níže uvedené údaje správné.',
    first_name: 'Jméno',
    last_name: 'Příjmení',
    date_of_birth: 'Datum narození',
    personal_id: 'Rodné číslo',
    identity_document_id: 'Číslo občanského průkazu',
    address: 'Adresa',
    email: 'Email',
    phone: 'Telefonní číslo',
    continue: 'Ano, údaje jsou správně',
    back: 'Ne, údaje potřebuji změnit',
  },

  new_offer_decreased: {
    html_title: 'Naše nabídka - Zaplo.cz',
    best_offer: 'Nejlepší nabídka',
    heading: 'Naše nabídka',
    message:
      'Omlouváme se, ale v tuto chvíli Vám půjčku v požadované výši nemůžeme poskytnout. Máme pro Vás ale novou nabídku.',
    you_applied_for: 'Žádali jste o',
    we_can_offer: 'Můžeme Vám nabídnout',
    table_heading: 'Přehled půjčky',
    loan: 'Půjčka',
    repayment_date: 'Den splatnosti půjčky',
    interest: 'Úrok',
    interest_rate: 'Zápůjční úroková sazba',
    total: 'Celkem splatíte',
    apply: 'Chci půjčku',
    explanation_heading: 'Proč byla částka snížena?',
    explanation_content_p1:
      'Na základě posouzení Vaší úvěryschopnosti Vám půjčku v požadované výši nemůžeme poskytnout, ale můžeme Vám poskytnout sníženou částku.',
    explanation_content_p2:
      'Pokud Vám bude nová nabídka vyhovovat, stačí ji pouze potvrdit a poté pokračovat na pročtení a souhlas s předsmluvními informacemi a smlouvou o úvěru.',
  },

  profile: {
    html_title: 'Můj profil - Zaplo.cz',
    heading: 'Můj profil',
    info_title_1: 'Podrobnosti mého účtu',
    first_name: 'Jméno',
    last_name: 'Příjmení',
    date_of_birth: 'Datum narození',
    personal_id: 'Rodné číslo',
    identity_document_id: 'Číslo občanského průkazu',
    bank_account: 'Číslo účtu',
    address: 'Adresa',
    password: 'Heslo',
    password_placeholder: '● ● ● ● ● ● ●',
    email: 'Email',
    phone: 'Telefonní číslo',
    info_title_2: 'Zasílání obchodních sdělení',

    // TODO: handle the actual modal implementation and translations in the other specific tickets
    modal: {
      header_name: 'Změna jména',
      header_last_name: 'Změna příjmení',
      header_date_of_birth: 'Datum narození',
      header_personal_id: 'Změna rodného čísla',
      header_identity_document_id: 'Změna čísla občanského průkazu',
      header_bank_account: 'Změna čísla účtu',
      header_address: 'Změna adresy',
      header_email: 'Změna emailu',
      header_phone: 'Změna telefonního čísla',
      header_password: 'Změna hesla',
      content_header_name: 'Jak změnit jméno',
      content_header_last_name: 'Jak změnit příjmení',
      content_header_date_of_birth: 'Jak změnit datum narození',
      content_header_personal_id: 'Jak změnit rodné číslo',
      content_header_identity_document_id:
        'Jak změnit číslo občanského průkazu',
      content_header_bank_account: 'Jak změnit číslo účtu',
      content_header_address: 'Jak změnit adresu',
      content_header_email: 'Změna e-mailu',
      content_header_phone: 'Změna telefonního čísla',
      content_header_password: 'Introducir contraseña',
      content_name:
        'Chcete-li změnit jméno, kontaktujte naši zákaznickou linku na čísle <a href="tel:800399399">800 399 399</a> nebo e-mailem na adrese <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>.',
      content_last_name:
        'Chcete-li změnit příjmení, kontaktujte naši zákaznickou linku na čísle <a href="tel:800399399">800 399 399</a> nebo e-mailem na adrese <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>.',
      content_date_of_birth:
        'Chcete-li změnit datum narození, kontaktujte naši zákaznickou linku na čísle <a href="tel:800399399">800 399 399</a> nebo e-mailem na adrese <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>.',
      content_personal_id:
        'Chcete-li změnit rodné číslo, kontaktujte naši zákaznickou linku na čísle <a href="tel:800399399">800 399 399</a> nebo e-mailem na adrese <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>.',
      content_identity_document_id:
        'Chcete-li změnit číslo občanského průkazu, kontaktujte naši zákaznickou linku na čísle <a href="tel:800399399">800 399 399</a> nebo e-mailem na adrese <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>.',
      content_bank_account:
        'Chcete-li změnit číslo účtu, kontaktujte naši zákaznickou linku na čísle <a href="tel:800399399">800 399 399</a> nebo e-mailem na adrese <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>.',
      content_address:
        'Chcete-li změnit adresu, kontaktujte naši zákaznickou linku na čísle <a href="tel:800399399">800 399 399</a> nebo e-mailem na adrese <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>.',
      content_email: 'Váš aktuální e-mail je:',
      content_email_done: 'Váš e-mail byl úspěšně změněn.',
      content_phone:
        'Zadejte Vaše nové telefonní číslo. Následně na něj odešleme SMS s potvrzovacím kódem, který zadáte v dalším kroku.',
      content_phone_done: 'Telefonní číslo bylo úspěšně změněno',
      placeholder_phone: 'Telefonní číslo',
      placeholder_email: 'Nová e-mailová adresa',
      old_password_placeholder: 'Původní heslo',
      new_password_placeholder: 'Nové heslo',
      confirm_new_password_placeholder: 'Potvrzení nového hesla',
      content_confirm_password: 'Prosím zadejte své heslo ještě jednou',
      content_password:
        'Heslo musí mít délku nejméně 7 znaků a musí obsahovat minimálně 1 číslici. Pozor, heslo rozlišuje velká a malá písmena. Nepoužívejte v hesle znaky s diakritikou.',
      content_password_done: 'Heslo bylo úspěšně změněno',
      change_phone_verify: {
        header: 'Potvrzovací kód zaslán na',
        pin_header: 'Potvrzovací kód',
        get_new_code: 'Znovu zaslat kód',
      },
      change_email_verify: {
        header: 'Potvrzovací kód zaslán na',
        pin_header: 'Potvrzovací kód',
        get_new_code: 'Znovu zaslat kód',
      },
      confirm_btn: 'Mám to',
      change_btn: 'POTVRDIT',
    },
  },

  login: {
    html_title: 'Přihlášení do zákaznického účtu',
    title: 'Přihlášení do zákaznického účtu',
    email: 'Email',
    password: 'Heslo',
    submit: 'PřihláSIT SE',
    forgot_password: 'Nepamatujete si heslo?',
    have_questions: 'Nějaké otázky?',
    back_url: 'Zpět na předchozí stránku',
    contact_details_holiday:
      'Kontaktujte naši zákaznickou linku na <a href="tel:800399399">800 399 399</a> nebo emailem <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>. <div>Po — Pá / 8:00 — 18:00</div>',
    contact_details:
      'Kontaktujte naši zákaznickou linku na <a href="tel:800399399">800 399 399</a> nebo emailem <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>. <div>Po — Pá / 8:00 — 19:00</div>',
  },

  twoFa: {
    html_title: 'Ověření telefonního čísla',
    title: 'Ověření telefonního čísla',
    subtitle:
      'Zadejte kód, který jsme vám zaslali prostřednictvím SMS na mobilní číslo, které jste si zaregistrovali u společnosti Zaplo.',
    email: 'Email',
    password: 'Heslo',
    submit: 'PřihláSIT SE',
    resend: 'Znovu odeslat kód',
    have_questions: 'Nějaké otázky?',
    info: 'Tyto další kroky zajišťují bezpečnost a soukromí vašeho účtu. Tímto způsobem potvrzujeme vaši totožnost a chráníme přístup k vašemu profilu.',
    contact_details:
      'Může chvíli trvat, než Vám SMS dorazí. Pokud jste SMS neobdrželi, tak pro dokončení žádosti kontaktujte naši zákaznickou linku na <a href="tel:800399399">800 399 399</a> nebo emailem <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>.',
  },

  password_recovery: {
    html_title: 'Zapomenuté heslo - Zaplo.cz',
    heading: 'Zapomenuté heslo',
    email: 'Email',
    continue: 'POKRAČOVAT',
    description: 'Zadejte Vaši emailovou adresu',
    help_heading: 'Nějaké otázky?',
    help_info:
      'Kontaktujte naši zákaznickou linku na <a href="tel:800399399">800 399 399</a> nebo emailem <a href="mailto:info@zaplo.cz">info@zaplo.cz</a> <div>Po — Pá / 8:00 — 19:00</div>',
    help_info_holidays:
      'Kontaktujte naši zákaznickou linku na <a href="tel:800399399">800 399 399</a> nebo emailem <a href="mailto:info@zaplo.cz">info@zaplo.cz</a> <div>Po — Pá / 8:00 — 18:00</div>',
  },

  password_recovery_sent: {
    html_title: 'Dočasné heslo odesláno na email - Zaplo.cz',
    heading: 'Dočasné heslo odesláno na email',
    description:
      'Na Váš email jsme odeslali dočasné heslo, kterým se nyní můžete přihlásit.',
    continue: 'PŘIHLÁSIT SE',
  },

  password_recovery_new_password: {
    html_title: 'Vytvořit nové heslo - Zaplo.cz',
    heading: 'Vytvořit nové heslo',
    new_password: 'Nové heslo',
    confirm_new_password: 'Potvrzení nového hesla',
    submit: 'ULOŽIT',
  },

  password_recovery_new_password_set: {
    html_title: 'Heslo bylo úspěšnězměněno - Zaplo.cz',
    heading: 'Heslo bylo úspěšně změněno',
    continue: 'POKRAČOVAT',
  },

  payments: {
    html_title: 'Potřebujete více času? - Zaplo.cz',
    heading: 'Potřebujete více času?',
    repayment: 'Splacení půjčky',
    extension: 'Prodloužení splatnosti půjčky',
    contract_number: 'Smlouva č.: {loanNumber}',
    step_1: 'Výběr možnosti platby',
    step_2: 'Proveďte platbu',
    step_3: 'Hotovo',
    extension_label_30: 'Prodloužit splatnost Vaší půjčky o 30 dní za:',
    extension_label_14: 'Prodloužit splatnost Vaší půjčky o 14 dní za:',
    extension_label_7: 'Prodloužit splatnost Vaší půjčky o 7 dní za:',
    repayment_label: 'Splatit celou půjčku (splatnost {paymentDate})',
    bank_info_1: '1. Otevřete svou online banku.',
    bank_info_2: '2. Proveďte splátku pomocí následujících platebních údajů.',
    bank_placeholder: 'Vyberte banku',
    bank_separator: 'NEBO',
    account_number: 'Číslo účtu',
    receiver: 'Příjemce',
    variable_symbol: 'Variabilní symbol',
    transfer_amount: 'Částka k úhradě',
    copy: 'KOPÍROVAT',
    copied: 'Zkopírováno',
    bank_info_subtitle:
      'Pro provedení platby použijte uvedené platební informace. Písemné potvrzení o přijetí Vaší platby Vám zašleme formou SMS a e-mailem v okamžiku, kdy platba dorazí na náš účet.',
    bank_info_subtitle_ext:
      'Pro prodloužení splatnosti Vaší půjčky použijte uvedené platební údaje. Písemné potvrzení o prodloužení splatnosti Vám zašleme formou sms a e-mailem v okamžiku přijetí Vaší platby.',
    agreement: 'Smlouva o úvěru',
    qrcode_notice:
      'Naskenujte QR kód pomocí mobilního telefonu a proveďte bankovní převod',
    qrcode_tooltip:
      'Naskenováním QR kódu nemusíte ručně kopírovat všechny údaje.',
    discount_banner: 'Máme pro vás {discountPercent}% slevu',
    discount_till: 'do {endDate}',
    download: 'stáhnout',
  },

  not_found: {
    html_title: 'Stránka nenalezena - Zaplo.cz',
    title: 'Stránka nenalezena',
    description:
      'Něco se vymňouklo. Stránka, na kterou se chcete dostat, bohužel neexistuje.',
  },

  income_proof_notification: {
    heading: 'Kopie potvrzení o příjmu',
    p_1: 'Zašlete nám prosím kopii některého z následujících dokumentů\n(ne starší 3 měsíců a to ve formátu: jpg, png nebo pdf):',
    item_1: 'Bankovní výpis',
    item_2: 'Potvrzení o příjmu od Vašeho zaměstnavatele',
    item_3: 'Výplatní pásku',
    item_4: 'Potvrzení o dávkách od státní správy sociálního zabezpečení',
    item_5: 'Potvrzení o výši důchodu',
    item_6: 'Potvrzení o výši dávek v nezaměstnanosti',
    item_7: 'Potvrzení příjmu na mateřské dovolené',
    p_2: 'V případě, že jste dosud nezaslali požadované dokumenty,\nzašlete je co nejdříve na následující e-mailovou adresu info@zaplo.cz',
  },

  id_proof_notification: {
    heading: 'Ověření totožnosti',
    p_1: 'Zašlete nám prosím Váš občanský průkaz.',
    p_2: 'Zašlete je co nejdříve na následující e-mailovou adresu info@zaplo.cz',
  },

  discount: {
    interest_before_discount: 'Úroková sazba před slevou',
    discount_applied: 'Sleva',
  },

  psd2_account_confirm: {
    html_title: 'Potvrďte číslo účtu',
    heading_mobile: 'Potvrďte číslo účtu',
    card_title: 'Bankovní údaje ',
    card_subtitle:
      'Na tento účet Vám odešleme peníze. Zkontrolujte si, že je účet správný.',
    confirm_button: 'ANO, účet je správně​',
    change_account_button: 'Ne, účet potřebuji změnit',
  },
  psd2_account_instructions: {
    html_title: 'Změna bankovního účtu',
    heading_mobile: 'Změna bankovního účtu',
    title: 'Změna bankovního účtu',
    card_title: 'Účet pro zaslání platby:',
    subtitle:
      'Pro změnu bankovního účtu je nutné provést za účelem identifikace ověřovací platbu ve výši 0,01 Kč (tj. jeden haléř). Platbu proveďte pod variabilním symbolem na níže uvedený účet.',
    confirm_button: 'Rozumím​',
    bank_info: {
      amount_to_be_paid: 'Částka k úhradě',
      variable_symbol: 'Variabilní symbol',
      recipient: 'Příjemce',
      account_number: 'Číslo účtu',
    },
  },
  psd2_manual: {
    html_title: 'Ověření bankovního účtu',
    heading_mobile: 'Nová nabídka',
    title: 'Ověření bankovního účtu',
    card_title: 'Účet pro zaslání platby:',
    subtitle:
      'Za účelem identifikace ověřovací platbu ve výši 0,01 Kč (tj. jeden haléř). Platbu proveďte pod variabilním symbolem na níže uvedený účet.',
    confirm_button: 'Rozumím​',
    bank_info: {
      amount_to_be_paid: 'Částka k úhradě',
      variable_symbol: 'Variabilní symbol',
      recipient: 'Příjemce',
      account_number: 'Číslo účtu',
    },
  },
  psd2_banks: {
    html_title: 'Nová nabídka',
    heading_mobile: 'Nová nabídka',
    title: 'Ověření bankovního účtu',
    subtitle: 'Vybraný účet bude použit k ověření příjmu.',
    search: 'Zadejte jméno banky',
    card_title: 'Vybraný účet bude použit k ověření příjmu.',
    card_subtitle:
      'Budete přesměrování na přihlašovací stránku vaší banky, kde se přihlásíte stejným způsobem jako do Vašeho internetového bankovnictví.',
    manual:
      'Pokud není Vaše banka na seznamu, můžete nám nahrát potvrzení o příjmu na následující stránce.',
    continue: 'Pokračovat',
    skip: 'Přeskočit',
    card_btn: 'Moje banka není na seznamu',
  },

  psd2_guide: {
    html_title: 'Ověření bankovního účtu – Zaplo.cz',
    title: 'Návod pro přihlášení do internetového bankovnictví',
    continue: 'Pokračovat',
    back: 'Zpět na výběr banky',
    default: {
      step_1: 'Přihlaste se do zvolené banky.',
      step_2: 'Po přihlášení připravíme stažení výpisů a ověřovací platbu.',
      step_3: 'Údaje jen potvrdíte a z účtu vás následně bezpečně odhlásíme.',
      additional_1:
        'Nezapomeňte, že pro úspěšné provedení ověřovací platby musí být na účtu alespoň minimální zůstatek 0,01 Kč.',
    },
  },

  psd2_consents: {
    heading_mobile: 'Souhlas',
    skip: 'Přeskočit',
    page_title: 'Udělení souhlasu',
    html_title: 'Udělení souhlasu – Zaplo.cz',
    description:
      'V následujícím kroku budete přesměrováni na přihlašovací stránku do internetového bankovnictví banky',
  },

  psd2_bank_account_select: {
    heading_mobile: 'Souhlas',
    html_title: 'Ověření bankovního účtu',
    page_title: 'Ověření bankovního účtu',
    description:
      'Ve Vašem bankovnictví jsme našli více účtů. Zvolte jeden účet, který chcete použít pro odeslání ověřovací platby ve výši 0,01 Kč.',
    account_label: 'Osobní účet CZK',
  },

  psd2_receiving: {
    heading_mobile: 'Souhlas',
    html_title: 'Ověření bankovního účtu',
    page_title: 'Ověření bankovního účtu',
    description:
      'Probíhá zpracování Vaší transakční historie. Prosíme o strpení, může to trvat několik minut ',
  },

  psd2_receiving_done: {
    html_title: 'Ověření bankovního účtuproběhlo v pořádku.',
    page_title: 'Ověření bankovního účtu',
    item_1: 'Zpracování transakční historie proběhlo v pořádku.',
    item_2: 'Z účtu jsme Vás bezpečně odhlásili.',
    continue: 'Pokračovat',
  },

  psd2_verified: {
    html_title: 'Ověření bankovního účtu proběhlo v pořádku.',
    page_title: 'Ověření bankovního účtu proběhlo v pořádku.',
    heading_mobile: 'Nová nabídka',
    complete: 'Dokončit žádost',
  },

  psd2_approved: {
    html_title: 'Nová nabídka',
    page_title: 'Vaše žádost o půjčku byla schválena',
    heading_mobile: 'Nová nabídka',
    description:
      'Peníze odesíláme ihned nebo vždy nejbližší pracovní den. Smlouvu naleznete v sekci informace o půjčce',
    complete: 'Zákaznický účet',
  },

  psd2_error: {
    html_title: 'Omlouváme se, něco se pokazilo.',
    page_title: 'Omlouváme se, něco se pokazilo.',
    continue_button: 'Pokračovat',
  },
};

export default cz;
