import { Loader } from '../../components';
import loadable from '@loadable/component';

export const AsyncDocumentUploadPending = loadable(
    () => import('./DocumentUploadPending'),
    {
        fallback: <Loader />,
    },
);

export const AsyncKYCUploadPending = AsyncDocumentUploadPending;