import { fetchData } from '../../utils';

const useClientLoans = () => {
  const fetchClientLoans = async () => {
    const data = await fetchData('/client/loans');
    return data;
  };
  return { fetchClientLoans };
};

export default useClientLoans;
