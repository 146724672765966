import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { fetchData } from '../../utils';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

type SmsVerification = { body: { code: string } };

type ConfirmationContextType = {
  sendCode: ({ body }: SmsVerification) => Promise<void>;
};

const ConfirmationContext = createContext<ConfirmationContextType>(
  {} as ConfirmationContextType,
);

const useConfirmation = (): ConfirmationContextType =>
  useContext(ConfirmationContext);

const ConfirmationProvider = ({ children }: Props): JSX.Element => {
  const { showError } = useError();

  const sendCode = useCallback(
    async ({ body }: SmsVerification) => {
      try {
        const data = await fetchData('/client/application/verify-code', {
          method: 'post',
          body: JSON.stringify(body),
        });

        return data;
      } catch (e) {
        if (e.name !== 'ValidationError') {
          showError();
        }
        throw e;
      }
    },
    [showError],
  );

  const ConfirmationContextValue = useMemo(() => {
    return {
      sendCode,
    };
  }, [sendCode]);

  return (
    <ConfirmationContext.Provider value={ConfirmationContextValue}>
      {children}
    </ConfirmationContext.Provider>
  );
};

export { useConfirmation as default, ConfirmationProvider };
