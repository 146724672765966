import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

export type Bank = {
  clientIdentificationNumber: string;
  clientPersonalId: string;
  bankName: string;
  registrationNumber: string;
  accountNumber: string;
  bic: string;
  homePageUrl: string;
  branchPageUrl: string;
  receiver: string;
  masterAccount: boolean;
  shortName: string;
};

export type Banks = {
  templates: Array<Bank>;
};

type BanksContextType = {
  banks?: Banks;
  fetchBanks: () => Promise<Banks | void>;
};

const BanksContext = createContext<BanksContextType>({} as BanksContextType);

const useBanks = (): BanksContextType => useContext(BanksContext);

const BanksProvider = ({ children }: Props): JSX.Element => {
  const [banks, setBanks] = useState<Banks>();
  const { showError } = useError();

  const fetchBanks = useCallback(async (): Promise<Banks | void> => {
    try {
      const fetchedBanks = await fetchData('/client/banks');

      setBanks(fetchedBanks);

      return fetchedBanks;
    } catch (e) {
      showError();
      throw e;
    }
  }, [showError]);

  const banksContextValue = useMemo(
    () => ({
      banks,
      fetchBanks,
    }),
    [banks, fetchBanks],
  );

  return (
    <BanksContext.Provider value={banksContextValue}>
      {children}
    </BanksContext.Provider>
  );
};

export { useBanks as default, BanksProvider };
