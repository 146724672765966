import Paragraph from '../Paragraph/Paragraph';
import styles from './HelpBlock.module.scss';

import { ReactComponent as HelpIcon } from '../../assets/icons/help.svg';
import classNames from 'classnames';

export type Props = {
  heading?: string;
  description: string;
  showIcon?: boolean;
  textAlignment?: 'center' | 'start';
  className?: string;
};

export default function HelpBlock({
  heading,
  description,
  showIcon = true,
  textAlignment = 'start',
  className,
}: Props) {
  return (
    <div
      className={classNames(
        styles['help-wrapper'],
        styles[`text-${textAlignment}`],
        className,
      )}
    >
      {showIcon && (
        <div className={styles['help-icon']}>
          <HelpIcon />
        </div>
      )}
      <div className={styles['help-info-wrapper']}>
        {heading && <Paragraph type="regular-bold">{heading}</Paragraph>}
        <p
          className={styles['information-text']}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    </div>
  );
}
