import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

export type PSD2ClientAccount = {
  number: string;
  iban: string;
};

type FetchPSD2ClientAccounts = (
  clientNumber: string,
  product: string,
) => Promise<{
  bankAccounts: PSD2ClientAccount[];
}>;

type ChoosePSD2Bank = (
  clientNumber: string,
  product: string,
  bankCode: string,
) => Promise<void>;

export type PSD2Banks = {
  code: string;
  name: string;
  logo: string;
};

export type FetchPSD2BanksResponse = {
  banks: PSD2Banks[];
};

type FetchPSD2Banks = () => Promise<FetchPSD2BanksResponse>;

type PSD2ClientConsentInformation = {
  consentType: string;
};

type FetchPSD2ClientConsentInformation = (
  clientNumber: string,
  product: string,
) => Promise<PSD2ClientConsentInformation>;

type PSD2IdentificationPayment = {
  url: string;
};

type InitializePSD2IdentificationPayment = (
  clientNumber: string,
  product: string,
  clientIBAN: string,
) => Promise<PSD2IdentificationPayment>;

type DownloadPSD2ClientTransactionHistory = (
  clientNumber: string,
  product: string,
) => Promise<void>;

type InitializePSD2Session = (requestBody: {
  product: string;
  clientNumber: string;
  bankAccountChange: boolean;
  bankCode: string;
}) => Promise<void>;

type PSD2ProcessResponse = {
  authenticated: boolean;
  url: string;
};

type ConfirmConsentAndAuthorizePSD2Client = (
  clientNumber: string,
  product: string,
  consent: boolean,
) => Promise<PSD2ProcessResponse>;

type PSD2SessionStatusResponse = {
  typeOfService: string;
  transactionHistory: string;
  paymentStatus: string;
  status: string;
};

type FetchPSD2SessionStatus = (
  clientNumber: string,
  product: string,
) => Promise<PSD2SessionStatusResponse>;

export type PSD2Availability = {
  psd2requested: boolean;
};

type PSD2ProxyContextType = {
  fetchPSD2ClientAccounts: FetchPSD2ClientAccounts;
  choosePSD2Bank: ChoosePSD2Bank;
  fetchPSD2ClientConsentInformation: FetchPSD2ClientConsentInformation;
  initializePSD2IdentificationPayment: InitializePSD2IdentificationPayment;
  downloadPSD2ClientTransactionHistory: DownloadPSD2ClientTransactionHistory;
  initializePSD2Session: InitializePSD2Session;
  confirmConsentAndAuthorizePSD2Client: ConfirmConsentAndAuthorizePSD2Client;
  fetchPSD2SessionStatus: FetchPSD2SessionStatus;
  fetchPSD2Banks: FetchPSD2Banks;
  fetchPSD2Availability: () => Promise<PSD2Availability | void>;
};

const PSD2ProxyContext = createContext<PSD2ProxyContextType>(
  {} as PSD2ProxyContextType,
);

const usePSD2Proxy = (): PSD2ProxyContextType => useContext(PSD2ProxyContext);

const PSD2ProxyProvider = ({ children }: Props): JSX.Element => {
  const { showError } = useError();

  const fetchPSD2Availability =
    useCallback(async (): Promise<PSD2Availability | void> => {
      try {
        const fetchedPSD2Availability = await fetchData(
          '/client/psd/psd2-requested',
        );

        return fetchedPSD2Availability;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const fetchPSD2ClientAccounts: FetchPSD2ClientAccounts = useCallback(
    async (clientNumber, product) => {
      try {
        const searchParams = new URLSearchParams({ clientNumber, product });

        const responseData = await fetchData(
          `/proxy/psd/accounts${
            Array.from(searchParams.values()).length
              ? '?' + searchParams.toString()
              : ''
          }`,
        );

        return responseData;
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  const choosePSD2Bank: ChoosePSD2Bank = useCallback(
    async (clientNumber, product, bankCode) => {
      try {
        const searchParams = new URLSearchParams({
          clientNumber,
          product,
          bankCode,
        });

        await fetchData(
          `/proxy/psd/bank${
            Array.from(searchParams.values()).length
              ? '?' + searchParams.toString()
              : ''
          }`,
          { method: 'PUT' },
        );
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  const fetchPSD2Banks: FetchPSD2Banks = useCallback(async () => {
    try {
      const responseData = await fetchData('/proxy/psd/banks');

      return responseData;
    } catch (e) {
      showError();
      throw e;
    }
  }, [showError]);

  const fetchPSD2ClientConsentInformation: FetchPSD2ClientConsentInformation =
    useCallback(
      async (clientNumber, product) => {
        try {
          const searchParams = new URLSearchParams({
            clientNumber,
            product,
          });

          const responseData = await fetchData(
            `/proxy/psd/consents/search${
              Array.from(searchParams.values()).length
                ? '?' + searchParams.toString()
                : ''
            }`,
          );

          return responseData;
        } catch (e) {
          showError();
          throw e;
        }
      },
      [showError],
    );

  const initializePSD2IdentificationPayment: InitializePSD2IdentificationPayment =
    useCallback(
      async (clientNumber, product, clientIBAN) => {
        try {
          const searchParams = new URLSearchParams({
            clientNumber,
            product,
            clientIban: clientIBAN,
          });

          const responseData = await fetchData(
            `/proxy/psd/payment${
              Array.from(searchParams.values()).length
                ? '?' + searchParams.toString()
                : ''
            }`,
            { method: 'POST' },
          );

          return responseData;
        } catch (e) {
          showError();
          throw e;
        }
      },
      [showError],
    );

  const downloadPSD2ClientTransactionHistory: DownloadPSD2ClientTransactionHistory =
    useCallback(async (clientNumber, product) => {
      try {
        const searchParams = new URLSearchParams({
          clientNumber,
          product,
        });

        await fetchData(
          `/proxy/psd/sessions/download${
            Array.from(searchParams.values()).length
              ? '?' + searchParams.toString()
              : ''
          }`,
          {
            method: 'POST',
            retryOn: [],
            retryDelay: undefined,
          },
        );
      } catch (e) {
        // Let's not show for now! =)
        // showError();
        throw e;
      }
    }, []);

  const initializePSD2Session: InitializePSD2Session = useCallback(
    async (requestBody) => {
      try {
        await fetchData(`/proxy/psd/sessions/init`, {
          method: 'POST',
          body: JSON.stringify(requestBody),
        });
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  const confirmConsentAndAuthorizePSD2Client: ConfirmConsentAndAuthorizePSD2Client =
    useCallback(
      async (clientNumber, product, consent) => {
        try {
          const searchParams = new URLSearchParams({
            clientNumber,
            product,
            consent: String(consent),
          });

          const responseData = await fetchData(
            `/proxy/psd/sessions/process${
              Array.from(searchParams.values()).length
                ? '?' + searchParams.toString()
                : ''
            }`,
            { method: 'POST' },
          );

          return responseData;
        } catch (e) {
          showError();
          throw e;
        }
      },
      [showError],
    );

  const fetchPSD2SessionStatus: FetchPSD2SessionStatus = useCallback(
    async (clientNumber, product) => {
      try {
        const searchParams = new URLSearchParams({
          clientNumber,
          product,
        });

        const responseData = await fetchData(
          `/proxy/psd/status${
            Array.from(searchParams.values()).length
              ? '?' + searchParams.toString()
              : ''
          }`,
        );

        return responseData;
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  const PSD2ProxyContextValue = useMemo(
    () => ({
      fetchPSD2ClientAccounts,
      choosePSD2Bank,
      fetchPSD2Banks,
      fetchPSD2ClientConsentInformation,
      initializePSD2IdentificationPayment,
      downloadPSD2ClientTransactionHistory,
      initializePSD2Session,
      confirmConsentAndAuthorizePSD2Client,
      fetchPSD2SessionStatus,
      fetchPSD2Availability,
    }),
    [
      fetchPSD2ClientAccounts,
      choosePSD2Bank,
      fetchPSD2Banks,
      fetchPSD2ClientConsentInformation,
      initializePSD2IdentificationPayment,
      downloadPSD2ClientTransactionHistory,
      initializePSD2Session,
      confirmConsentAndAuthorizePSD2Client,
      fetchPSD2SessionStatus,
      fetchPSD2Availability,
    ],
  );

  return (
    <PSD2ProxyContext.Provider value={PSD2ProxyContextValue}>
      {children}
    </PSD2ProxyContext.Provider>
  );
};

export { usePSD2Proxy as default, PSD2ProxyProvider };
