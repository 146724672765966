import { Card, Heading } from '..';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import classes from 'classnames';
import React, { memo, ReactNode, useEffect, useRef } from 'react';
import styles from './Modal.module.scss';

export type Props = {
  onClose?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | MouseEvent,
  ) => void;
  children?: ReactNode;
  backdropClose?: boolean;
  className?: string;
  containerClassName?: string;
  dataTest?: string;
  heading?: string;
  contentClassName?: string;
};

export default memo(function Modal({
  onClose,
  children,
  backdropClose = true,
  className,
  containerClassName,
  dataTest,
  heading,
  contentClassName,
}: Props) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (overlayRef.current && backdropClose) {
      overlayRef.current.addEventListener('mouseup', (event) => {
        if (onClose) {
          onClose(event);
        }
      });
    }
  }, [overlayRef, onClose, backdropClose]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('mouseup', (event) => {
        event.stopPropagation();
      });
    }
  }, [containerRef]);

  return (
    <div ref={overlayRef} className={styles.overlay} data-test={dataTest}>
      <div
        className={classes(styles.container, styles.fade, containerClassName)}
      >
        <Card className={classes(styles.card, className)} ref={containerRef}>
          <header>
            {heading && (
              <Heading className={styles.heading} size="s">
                <span className={styles['modal-header']}>
                  {heading}
                </span>
              </Heading>
            )}
            {onClose && (
              <button
                data-test="close-modal"
                onClick={onClose}
                className={styles.close}
                type="button"
              >
                <CloseIcon />
              </button>
            )}
          </header>
          <div className={classes(styles.content, contentClassName)}>
            {children}
          </div>
        </Card>
      </div>
    </div>
  );
});
