import { ADDITIONAL_AMOUNT, REPEATED_LOAN } from './../routes';
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useApplicationUpsale from '../../hooks/webapi/useApplicationUpsale';

type Props = {
  children: ReactElement;
  additionalAmount?: boolean;
};

export default function NewOfferIncreasedGuard({
  children,
  additionalAmount = false,
  ...props
}: Props) {
  const { fetchApplicationUpsale } = useApplicationUpsale();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const fetchedOffer = await fetchApplicationUpsale();
      if (fetchedOffer) {
        if (fetchedOffer?.amount === null) {
          navigate(additionalAmount ? ADDITIONAL_AMOUNT : REPEATED_LOAN);
        }
      }
    })();
  }, [fetchApplicationUpsale, navigate, additionalAmount]);

  return children;
}
