import { LOGIN, PASSWORD_RECOVERY_NEW_PASSWORD } from './routes';
import { Navigate, useNavigate } from 'react-router-dom';
import { ReactElement, useEffect } from 'react';

type Props = {
  children: ReactElement;
  [index: string]: any;
};

export default function PrivateRoute({ children, ...props }: Props) {
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem('fromTemporaryPassword') === 'true') {
      navigate(PASSWORD_RECOVERY_NEW_PASSWORD, { replace: true });
    }
  }, [navigate]);

  if (sessionStorage.getItem('token')) {
    return children;
  }

  return <Navigate to={LOGIN} replace />;
}
