import fetchData from '../../utils/fetchData';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type Props = {
  children: ReactNode;
};

/** List of possible consent names */
export type ConsentName =
  | 'ACCEPT_NEWS'
  | 'ACCEPT_MARKETING_EMAILS'
  | 'ACCEPT_MARKETING_SMS'
  | 'ACCEPT_MARKETING_CALLS'
  | 'ACCEPT_CREDIT_AGREEMENT'
  | 'ACCEPT_CREDIT_BUREAU_CHECK'
  | 'ACCEPT_DATA_SHARING'
  | 'ACCEPT_FACE_ID_PROCESSING'
  | 'ACCEPT_INTERNAL_DATA_SHARING'
  | 'ACCEPT_PRIVACY_POLICY'
  | 'ACCEPT_PROFILING'
  | 'ACCEPT_TELCO_SCANNING'
  | 'PROCESSING_OF_PERSONAL_DATA'
  | 'PSD2_DATA_PROCESSING'
  | 'PSD2_DATA_PROCESSING_TRANSACTION_HISTORY';

/** Individual consent format when received from webapi */
export type Consent = {
  active: boolean;
  collectNew: boolean;
  name: ConsentName;
  parentConsent?: 'ACCEPT_NEWS';
  createdDate: string;
  effectiveFromDate: string;
  accepted?: boolean; // This is a field added on FE side
  content: Array<{
    locale: 'cz'; // Language of the consent
    text: string; // Holds the HTML of the consent label
  }>;
  version: number;
};

export type FormattedConsents = {
  // eslint-disable-next-line no-unused-vars -- eslint doesn't understand this typescript syntax
  [index in ConsentName]: Consent;
};

type ConsentsContextType = {
  consents?: FormattedConsents;
  fetchAvailableConsents: () => Promise<FormattedConsents | void>;
  fetchAcceptedConsents: () => Promise<FormattedConsents | void>;
  fetchConsents: () => Promise<FormattedConsents | void>;
  toggleConsent: (
    name: ConsentName,
    accepted: boolean,
    version?: number,
  ) => Promise<void>;
};

const ConsentsContext = createContext<ConsentsContextType>(
  {} as ConsentsContextType,
);

const useConsents = (): ConsentsContextType => useContext(ConsentsContext);

const ConsentsProvider = ({ children }: Props): JSX.Element => {
  const [consents, setConsents] = useState<FormattedConsents>();

  /** Format consents into an Object so that it's easier to get specific consents */
  const convertConsentsToObject = useCallback(
    (consentsObject: Consent[], accepted: boolean) => {
      return consentsObject.reduce((acc, curr) => {
        acc[curr.name] = curr;
        acc[curr.name].accepted = accepted; // Add this extra field to easily know if a consent is accepted or not
        return acc;
      }, {} as FormattedConsents);
    },
    [],
  );

  /** Fetch latest available consents */
  const fetchAvailableConsents =
    useCallback(async (): Promise<FormattedConsents | void> => {
      try {
        const fetchedAvailableConsents = await fetchData(
          '/external/consents/data',
        );

        const formattedConsents = convertConsentsToObject(
          fetchedAvailableConsents.entries,
          false,
        );

        return formattedConsents;
      } catch (e) {
        throw e;
      }
    }, [convertConsentsToObject]);

  /** Fetch the consents that the client has accepted */
  const fetchAcceptedConsents =
    useCallback(async (): Promise<FormattedConsents | void> => {
      try {
        const fetchedAcceptedConsents = await fetchData(
          '/client/consents/accepted/data',
        );

        const formattedConsents = convertConsentsToObject(
          fetchedAcceptedConsents?.entries || [],
          true,
        );

        return formattedConsents;
      } catch (e) {
        throw e;
      }
    }, [convertConsentsToObject]);

  /** Fetch both accepted and available consents and merge both in one object */
  const fetchConsents =
    useCallback(async (): Promise<FormattedConsents | void> => {
      try {
        const [fetchedAvailableConsents, fetchedAcceptedConsents] =
          await Promise.all([
            fetchAvailableConsents(),
            fetchAcceptedConsents(),
          ]);

        if (fetchedAvailableConsents && fetchedAcceptedConsents) {
          /** Holds the consent version for accepted consents, otherwise holds the latest available version of a consent */
          const mergedConsents = {
            ...fetchedAvailableConsents,
            ...fetchedAcceptedConsents,
          };

          setConsents(mergedConsents);
          return mergedConsents;
        }
      } catch (e) {
        throw e;
      }
    }, [fetchAcceptedConsents, fetchAvailableConsents]);

  const toggleConsent = useCallback(
    async (name: ConsentName, accepted: boolean, version?: number) => {
      try {
        const consentPayload = {
          consents: [
            {
              name,
              accepted,
              ...(accepted && { version }),
            },
          ],
        };
        const consentResponse = await fetchData('/client/settings', {
          method: 'put',
          body: JSON.stringify(consentPayload),
        });

        return consentResponse;
      } catch (e) {}
    },
    [],
  );

  const consentsContextValue = useMemo(
    () => ({
      consents,
      fetchAvailableConsents,
      fetchAcceptedConsents,
      fetchConsents,
      toggleConsent,
    }),
    [
      consents,
      fetchAcceptedConsents,
      fetchAvailableConsents,
      fetchConsents,
      toggleConsent,
    ],
  );

  return (
    <ConsentsContext.Provider value={consentsContextValue}>
      {children}
    </ConsentsContext.Provider>
  );
};

export { useConsents as default, ConsentsProvider };
