import * as R from '../../Router/routes';
import { datadogLogs } from '@datadog/browser-logs';
import { useCallback, useMemo } from 'react';
import useError from '../useError';

export type DocumentType =
  | 'frontSideDni'
  | 'backSideDni'
  | 'bankReceipt'
  | 'idCard'
  | 'passport'
  | 'gunLicense'
  | 'driversLicense'
  | 'photo'
  | 'otherDocument'
  | 'incomeDocument';

const useDocumentUpload = () => {
  const { showError } = useError();

  const documentUpload = useCallback(
    async (file: File, type: DocumentType) => {
      try {
        const formData = new FormData();

        formData.append('file', file);
        formData.append('type', type);

        const path = '/client/attachments';
        const url = `${process.env.REACT_APP_WEB_API}${path}`;
        // NOTE: When sending FormData, 'Content-Type' header must be undefined,
        // so that the browser would set the header automatically
        // TODO: utilize with fetchData method
        const response = await fetch(url, {
          method: 'post',
          headers: {
            ...(sessionStorage.getItem('token') && {
              Authorization: `Basic ${sessionStorage.getItem('token')}`,
            }),
          },
          body: formData,
        });
        if (response.status === 401) {
          sessionStorage.removeItem('token');
          window.location.replace(`${R.LOGIN}?sessionExpired=true`);
          return;
        }

        if (!response.ok) {
          try {
            const data = await response.json();

            datadogLogs.logger.error(`request: ${path}`, {
              request_url: url,
              error: data,
            });
          } catch (exception) {
            datadogLogs.logger.error(`request: ${path} response parse`, {
              request_url: url,
              error: exception,
            });
          }

          throw new Error(response.status.toString());
        }

        return response;
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  return useMemo(() => {
    return {
      documentUpload,
    };
  }, [documentUpload]);
};

export default useDocumentUpload;
