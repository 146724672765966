import * as R from './routes';
import { AsyncActiveLoan } from '../pages/ActiveLoan/async';
import { AsyncAdditionalAmount } from '../pages/AdditionalAmount/async';
import { AsyncAdditionalAmountLoanBudget } from '../pages/AdditionalAmountLoanBudget/async';
import { AsyncDocumentUploadPending } from '../pages/DocumentUploadPending/async';
import { AsyncFAQ } from '../pages/FAQ/async';
import { AsyncKYCUploadPending } from '../pages/DocumentUploadPending/async';
import { AsyncLoanApproved } from '../pages/LoanApproved/async';
import { AsyncLoanBudget } from '../pages/LoanBudget/async';
import { AsyncLoanConfirm } from '../pages/LoanConfirm/async';
import { AsyncLoanDetails } from '../pages/LoanDetails/async';
import { AsyncLoanRejected } from '../pages/LoanRejected/async';
import { AsyncLogin, AsyncOneTimeTokenLogin } from '../pages/Login/async';
import { AsyncNewOfferDecreased } from '../pages/NewOfferDecreased/async';
import { AsyncNewOfferIncreased } from '../pages/NewOfferIncreased/async';
import { AsyncNotFound } from '../pages/NotFound/async';
import { AsyncOverdueLoan } from '../pages/OverdueLoan/async';
import { AsyncPasswordRecovery } from '../pages/PasswordRecovery/async';
import { AsyncPasswordRecoveryNewPassword } from '../pages/PasswordRecoveryNewPassword/async';
import { AsyncPasswordRecoveryNewPasswordSet } from '../pages/PasswordRecoveryNewPasswordSet/async';
import { AsyncPasswordRecoverySent } from '../pages/PasswordRecoverySent/async';
import { AsyncPayments } from '../pages/Payments/async';
import { AsyncPersonalDataConfirm } from '../pages/PersonalDataConfirm/async';
import { AsyncProfile } from '../pages/Profile/async';
import { AsyncPSD2AccountConfirm } from '../pages/PSD2AccountConfirm/async';
import { AsyncPSD2AccountInstructions } from '../pages/PSD2AccountInstructions/async';
import { AsyncPSD2Approved } from '../pages/PSD2Approved/async';
import { AsyncPSD2BankAccountSelect } from '../pages/PSD2SelectBankAccount/async';
import { AsyncPSD2Banks } from '../pages/PSD2Banks/async';
import { AsyncPSD2Consents } from '../pages/PSD2Consents/async';
import { AsyncPSD2Error } from '../pages/PSD2Error/async';
import { AsyncPSD2Receiving } from '../pages/PSD2Receiving/async';
import { AsyncPSD2Verified } from '../pages/PSD2Verified/async';
import { AsyncRepeatedLoan } from '../pages/RepeatedLoan/async';
import { AsyncUploadDocument } from '../pages/UploadDocument/async';
import { AsyncUploadKYC } from '../pages/UploadKYC/async';
import { Navigate, Route, Routes } from 'react-router-dom';
import NewOfferIncreasedGuard from './Guards/NewOfferIncreasedGuard';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import styles from './Router.module.scss';
import TwoFA from '../pages/TwoFA/TwoFA';

export default function AppRouter() {
  return (
    <div className={styles.router}>
      <Routes>
        <Route path="/" element={<Navigate to={R.LOGIN} />} />
        <Route
          path={R.LOGIN}
          element={
            <PublicRoute>
              <AsyncLogin />
            </PublicRoute>
          }
        />
        <Route path={R.TWO_FA} element={<TwoFA />} />
        <Route
          path={R.ONE_TIME_TOKEN_LOGIN}
          element={
            <PublicRoute>
              <AsyncOneTimeTokenLogin />
            </PublicRoute>
          }
        />
        <Route
          path={R.PASSWORD_RECOVERY}
          element={
            <PublicRoute>
              <AsyncPasswordRecovery />
            </PublicRoute>
          }
        />
        <Route
          path={R.PASSWORD_RECOVERY_SENT}
          element={
            <PublicRoute>
              <AsyncPasswordRecoverySent />
            </PublicRoute>
          }
        />
        <Route
          path={R.PASSWORD_RECOVERY_NEW_PASSWORD}
          element={
            <PrivateRoute>
              <AsyncPasswordRecoveryNewPassword />
            </PrivateRoute>
          }
        />
        <Route
          path={R.PASSWORD_RECOVERY_NEW_PASSWORD_SET}
          element={
            <PrivateRoute>
              <AsyncPasswordRecoveryNewPasswordSet />
            </PrivateRoute>
          }
        />
        <Route
          path={R.ACTIVE_LOAN}
          element={
            <PrivateRoute>
              <AsyncActiveLoan />
            </PrivateRoute>
          }
        />
        <Route
          path={R.OVERDUE_LOAN}
          element={
            <PrivateRoute>
              <AsyncOverdueLoan />
            </PrivateRoute>
          }
        />
        <Route
          path={R.REPEATED_LOAN}
          element={
            <PrivateRoute>
              <AsyncRepeatedLoan />
            </PrivateRoute>
          }
        />
        <Route
          path={R.PERSONAL_DATA_CONFIRM}
          element={
            <PrivateRoute>
              <AsyncPersonalDataConfirm />
            </PrivateRoute>
          }
        />
        <Route
          path={R.DOCUMENT_UPLOAD}
          element={
            <PrivateRoute>
              <AsyncUploadDocument />
            </PrivateRoute>
          }
        />
        <Route
          path={R.LOAN_APPROVED}
          element={
            <PrivateRoute>
              <AsyncLoanApproved />
            </PrivateRoute>
          }
        />
        <Route
          path={R.DOCUMENT_UPLOAD_PENDING}
          element={
            <PrivateRoute>
              <AsyncDocumentUploadPending />
            </PrivateRoute>
          }
        />
        <Route
          path={R.KYC_UPLOAD}
          element={
            <PrivateRoute>
              <AsyncUploadKYC />
            </PrivateRoute>
          }
        />
        <Route
          path={R.KYC_UPLOAD_PENDING}
          element={
            <PrivateRoute>
              <AsyncKYCUploadPending />
            </PrivateRoute>
          }
        />
        <Route
          path={R.ADDITIONAL_AMOUNT_LOAN_BUDGET}
          element={
            <PrivateRoute>
              <AsyncAdditionalAmountLoanBudget />
            </PrivateRoute>
          }
        />
        <Route
          path={R.ADDITIONAL_AMOUNT_REJECT}
          element={
            <PrivateRoute>
              <AsyncLoanRejected />
            </PrivateRoute>
          }
        />
        <Route
          path={R.ADDITIONAL_AMOUNT_NEW_OFFER_INCREASE}
          element={
            <PrivateRoute>
              <NewOfferIncreasedGuard additionalAmount>
                <AsyncNewOfferIncreased additionalAmount />
              </NewOfferIncreasedGuard>
            </PrivateRoute>
          }
        />
        <Route
          path={R.ADDITIONAL_AMOUNT_NEW_OFFER_INCREASE_CALCULATOR}
          element={
            <PrivateRoute>
              <NewOfferIncreasedGuard additionalAmount>
                <AsyncAdditionalAmount />
              </NewOfferIncreasedGuard>
            </PrivateRoute>
          }
        />
        <Route
          path={R.ADDITIONAL_AMOUNT_APPROVED}
          element={
            <PrivateRoute>
              <AsyncLoanApproved />
            </PrivateRoute>
          }
        />
        <Route
          path={R.ADDITIONAL_AMOUNT_CONFIRM}
          element={
            <PrivateRoute>
              <AsyncLoanConfirm />
            </PrivateRoute>
          }
        />
        <Route
          path={R.LOAN_BUDGET}
          element={
            <PrivateRoute>
              <AsyncLoanBudget />
            </PrivateRoute>
          }
        />
        <Route
          path={R.LOAN_CONFIRM}
          element={
            <PrivateRoute>
              <AsyncLoanConfirm />
            </PrivateRoute>
          }
        />
        <Route
          path={R.LOAN_REJECTED}
          element={
            <PrivateRoute>
              <AsyncLoanRejected />
            </PrivateRoute>
          }
        />
        <Route
          path={R.NEW_OFFER_INCREASED}
          element={
            <PrivateRoute>
              <NewOfferIncreasedGuard>
                <AsyncNewOfferIncreased />
              </NewOfferIncreasedGuard>
            </PrivateRoute>
          }
        />
        <Route
          path={R.NEW_OFFER_INCREASED_CALCULATOR}
          element={
            <PrivateRoute>
              <AsyncRepeatedLoan />
            </PrivateRoute>
          }
        />
        <Route
          path={R.NEW_OFFER_DECREASED}
          element={
            <PrivateRoute>
              <AsyncNewOfferDecreased />
            </PrivateRoute>
          }
        />
        <Route
          path={R.ADDITIONAL_AMOUNT}
          element={
            <PrivateRoute>
              <AsyncAdditionalAmount />
            </PrivateRoute>
          }
        />
        <Route
          path={R.PROFILE}
          element={
            <PrivateRoute>
              <AsyncProfile />
            </PrivateRoute>
          }
        />
        <Route
          path={`${R.PROFILE}/:modalId`}
          element={
            <PrivateRoute>
              <AsyncProfile />
            </PrivateRoute>
          }
        />
        <Route
          path={R.EXTENSION}
          element={
            <PrivateRoute>
              <AsyncPayments typeOfPayment="extension" />
            </PrivateRoute>
          }
        />
        <Route
          path={R.REPAYMENT}
          element={
            <PrivateRoute>
              <AsyncPayments typeOfPayment="repayment" />
            </PrivateRoute>
          }
        />
        <Route
          path={R.LOAN_DETAILS}
          element={
            <PrivateRoute>
              <AsyncLoanDetails />
            </PrivateRoute>
          }
        />

        <Route
          path={R.PSD2_ACCOUNT_CONFIRM}
          element={
            <PrivateRoute>
              <AsyncPSD2AccountConfirm />
            </PrivateRoute>
          }
        />
        <Route
          path={R.PSD2_ACCOUNT_CHANGE_INSTRUCTIONS}
          element={
            <PrivateRoute>
              <AsyncPSD2AccountInstructions />
            </PrivateRoute>
          }
        />

        <Route
          path={R.PSD2_BANKS}
          element={
            <PrivateRoute>
              <AsyncPSD2Banks />
            </PrivateRoute>
          }
        />
        <Route
          path={`${R.PSD2_BANKS}/:modalId`}
          element={
            <PrivateRoute>
              <AsyncPSD2Banks />
            </PrivateRoute>
          }
        />
        <Route
          path={R.PSD2_BANK_ACCOUNT_SELECT}
          element={
            <PrivateRoute>
              <AsyncPSD2BankAccountSelect />
            </PrivateRoute>
          }
        />
        <Route
          path={R.PSD2_CONSENTS}
          element={
            <PrivateRoute>
              <AsyncPSD2Consents />
            </PrivateRoute>
          }
        />
        <Route
          path={R.PSD2_RECEIVING}
          element={
            <PrivateRoute>
              <AsyncPSD2Receiving />
            </PrivateRoute>
          }
        />
        <Route
          path={R.PSD2_VERIFIED}
          element={
            <PrivateRoute>
              <AsyncPSD2Verified />
            </PrivateRoute>
          }
        />
        <Route
          path={R.PSD2_ERROR}
          element={
            <PrivateRoute>
              <AsyncPSD2Error />
            </PrivateRoute>
          }
        />
        <Route
          path={R.PSD2_APPROVED}
          element={
            <PrivateRoute>
              <AsyncPSD2Approved />
            </PrivateRoute>
          }
        />

        <Route path={R.FAQ} element={<AsyncFAQ />} />

        <Route path={R.NOT_FOUND_GENERAL} element={<AsyncNotFound />} />
      </Routes>
    </div>
  );
}
