import { datadogLogs } from '@datadog/browser-logs';
import { LOGIN } from '../Router/routes';
import ValidationError, { FieldError } from './ValidationError';

export const fetchRetry = require('fetch-retry')(fetch);
export const defaultOptions = {
  retryOn: [503, 500],
  retries: (window as any).Cypress ? 0 : 3,
  retryDelay: 1500,
};

async function fetchData(
  path: string,
  customOptions?: RequestInit & Partial<typeof defaultOptions>,
  /**
    don't log 404 status for some endpoints
    as BE return this status for non active state and it will clog logging data
   */
  ignoreNotFoundLogger?: boolean,
  params?: Record<string, string | number | boolean | undefined>,
) {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(sessionStorage.getItem('token') && {
      Authorization: `Basic ${sessionStorage.getItem('token')}`,
    }),
  };

  const options = {
    ...defaultOptions,
    ...customOptions,
    headers: {
      ...defaultHeaders,
      ...customOptions?.headers,
    },
  };

  /* This will parse the params object to query parameters to format parameter=value, removing undefined fields.
  // Filter method removes potential undefined fields, but for some reasons the function returns [string]: string | undefined.
  // For that reason there is this ugly type assertion
  */

  const searchString =
    params &&
    new URLSearchParams(
      Object.fromEntries(Object.entries(params).filter(([, v]) => v)) as Record<
        string,
        string
      >,
    );

  const formattedParams = searchString ? `?${searchString}` : '';
  const url = `${process.env.REACT_APP_WEB_API}${path}${formattedParams}`;
  const response = await fetchRetry(url, options);

  if (
    (response.status === 401 || response.status === 403) &&
    (window as any).Cypress
  ) {
    // Return undefined during cypress tests when a request hasn't been intercepted in tests
    return undefined;
  }

  if (!response.ok && !(response.status === 404 && ignoreNotFoundLogger)) {
    try {
      const data = await response.clone().json();

      datadogLogs.logger.error(`request: ${path}`, {
        request_url: url,
        error: data,
      });
    } catch (exception) {
      datadogLogs.logger.error(`request: ${path} response parse`, {
        request_url: url,
        error: exception,
      });
    }
  }

  // 403 status code gets returned when client has been blocked via COLA
  if (response.status === 401 || response.status === 403) {
    const queryString = window.location.search
      ? `${window.location.search}&sessionExpired=true`
      : '?sessionExpired=true';
    sessionStorage.removeItem('token');
    window.location.replace(`${LOGIN}${queryString}`);
    return;
  }

  if (response.status === 400) {
    const { errors }: { errors: FieldError[] } = await response.json();
    throw new ValidationError(errors);
  }

  if (!response.ok) {
    throw new Error(response.status.toString());
  }

  try {
    let data;
    if (options.headers['Accept'] === 'text/html') {
      data = await response.text();
    } else if (options.headers['Accept'] === 'application/pdf') {
      data = await response.blob();
    } else {
      data = await response.json();
    }
    return data;
  } catch {
    // If response doesn't have content return undefined;
    return;
  }
}

export default fetchData;
