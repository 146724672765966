import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

type ApplicationUpsale = {
  amount: number;
};

type RejectUpsaleResponse = {
  rejected: boolean;
};

type AcceptUpsaleResponse = {
  rejected: boolean;
};

type AcceptUpsaleOfferOptions = {
  body: {
    amount: number;
  };
};

type ApplicationUpsaleContextType = {
  applicationUpsale: ApplicationUpsale | undefined;
  fetchApplicationUpsale: () => Promise<ApplicationUpsale | void>;
  acceptUpsaleOffer: ({
    body,
  }: AcceptUpsaleOfferOptions) => Promise<AcceptUpsaleResponse | void>;
  rejectUpsaleOffer: () => Promise<RejectUpsaleResponse | void>;
};

const ApplicationUpsaleContext = createContext<ApplicationUpsaleContextType>(
  {} as ApplicationUpsaleContextType,
);

const useApplicationUpsale = (): ApplicationUpsaleContextType =>
  useContext(ApplicationUpsaleContext);

const ApplicationUpsaleProvider = ({ children }: Props): JSX.Element => {
  const [applicationUpsale, setApplicationUpsale] = useState<
    ApplicationUpsale | undefined
  >();
  const { showError } = useError();

  const fetchApplicationUpsale =
    useCallback(async (): Promise<ApplicationUpsale | void> => {
      try {
        const fetchedApplicationUpsale = await fetchData(
          '/client/application/upsale/display',
        );

        setApplicationUpsale(fetchedApplicationUpsale);
        return fetchedApplicationUpsale;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const acceptUpsaleOffer = useCallback(
    async ({
      body,
    }: AcceptUpsaleOfferOptions): Promise<AcceptUpsaleResponse | void> => {
      try {
        const data = await fetchData('/client/application/upsale/accept', {
          method: 'post',
          body: JSON.stringify(body),
        });

        return data;
      } catch (e) {
        throw e;
      }
    },
    [],
  );

  const rejectUpsaleOffer =
    useCallback(async (): Promise<RejectUpsaleResponse | void> => {
      try {
        const data = await fetchData('/client/application/upsale/reject', {
          method: 'post',
        });
        return data;
      } catch (e) {
        throw e;
      }
    }, []);

  const ApplicationUpsaleContextValue = useMemo(
    () => ({
      applicationUpsale,
      fetchApplicationUpsale,
      acceptUpsaleOffer,
      rejectUpsaleOffer,
    }),
    [
      applicationUpsale,
      fetchApplicationUpsale,
      acceptUpsaleOffer,
      rejectUpsaleOffer,
    ],
  );

  return (
    <ApplicationUpsaleContext.Provider value={ApplicationUpsaleContextValue}>
      {children}
    </ApplicationUpsaleContext.Provider>
  );
};

export { useApplicationUpsale as default, ApplicationUpsaleProvider };
