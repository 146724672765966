import { memo, useEffect, useMemo, useState } from 'react';
import classes from 'classnames';
import styles from './Timeout.module.scss';

export type Props = {
  initialSeconds?: number;
  onTimeout: () => void;
  className?: string;
};

const Timeout = memo(({ initialSeconds = 60, onTimeout, className }: Props) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  const date = new Date(0);
  date.setSeconds(seconds);

  const sliceFrom = useMemo(() => {
    let charNumber = 15;

    if (initialSeconds >= 600 && initialSeconds < 3600) {
      charNumber = 14;
    } else if (initialSeconds >= 3600 && initialSeconds < 36000) {
      charNumber = 12;
    } else if (initialSeconds >= 36000 && initialSeconds < 86400) {
      charNumber = 11;
    }
    return charNumber;
  }, [initialSeconds]);

  const timeString = date.toISOString().slice(sliceFrom, 19);

  useEffect(() => {
    const countdown = setInterval(() => {
      setSeconds((prevTime) => {
        const newTime = prevTime - 1;
        if (newTime <= 0) {
          clearInterval(countdown);
          onTimeout();
        }
        return newTime;
      });
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [onTimeout]);

  return <span className={classes(styles.timer, className)}>{timeString}</span>;
});

export default Timeout;
