import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { fetchData } from '../../utils';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

type ApplicationAgreementPdf = {
  amount: string;
};

type LegalDocumentContextType = {
  fetchLoanAgreement: () => Promise<Blob | void>;
  applicationAgreement?: string;
  fetchApplicationAgreement: () => Promise<string | void>;
  fetchApplicationAgreementPdf: (
    amount: ApplicationAgreementPdf,
  ) => Promise<Blob | void>;
  applicationSecci?: string;
  fetchApplicationSecci: () => Promise<string | void>;
  fetchAmortizationSchedule: () => Promise<Blob | void>;
  fetchApplicationSecciPdf: () => Promise<Blob | void>;
};

const LegalDocumentContext = createContext<LegalDocumentContextType>(
  {} as LegalDocumentContextType,
);

const useLegalDocuments = (): LegalDocumentContextType =>
  useContext(LegalDocumentContext);

const LegalDocumentProvider = ({ children }: Props): JSX.Element => {
  const [applicationAgreement, setApplicationAgreement] = useState<string>();
  const [applicationSecci, setApplicationSecci] = useState<string>();

  const { showError } = useError();

  /** Fetches the HTML SECCI form of an open application */
  const fetchApplicationSecci = useCallback(async (): Promise<
    string | void
  > => {
    try {
      const res = await fetchData('/client/application/secci-html', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/html',
        },
      });
      setApplicationSecci(res);
      return res;
    } catch (e) {
      showError();
    }
  }, [showError]);

  /** Fetches the PDF SECCI form of an open application */
  const fetchApplicationSecciPdf =
    useCallback(async (): Promise<Blob | void> => {
      try {
        const res = await fetchData('/client/application/secci', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
          },
        });

        return res;
      } catch (e) {
        showError();
      }
    }, [showError]);

  /** Fetches the HTML agreement of an open application */
  const fetchApplicationAgreement = useCallback(async (): Promise<
    string | void
  > => {
    try {
      const res = await fetchData('/client/agreements/latest-html', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/html',
        },
      });
      setApplicationAgreement(res);
      return res;
    } catch (e) {
      showError();
    }
  }, [showError]);

  const fetchApplicationAgreementPdf = useCallback(
    async ({ amount }: ApplicationAgreementPdf): Promise<Blob | void> => {
      try {
        const res = await fetchData(
          `/client/agreements/preliminary?amount=${amount}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf',
            },
          },
        );
        return res;
      } catch (e) {
        showError();
      }
    },
    [showError],
  );

  /** Fetches the PDF agreement of an open loan */
  const fetchLoanAgreement = useCallback(async (): Promise<Blob | void> => {
    try {
      const res = await fetchData('/client/loans/latest/agreement', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      });

      return res;
    } catch (e) {
      showError();
    }
  }, [showError]);

  /** Fetches the PDF amortization schedule of an open loan */
  const fetchAmortizationSchedule =
    useCallback(async (): Promise<Blob | void> => {
      try {
        const res = await fetchData('/client/amortization-schedule', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
          },
        });

        return res;
      } catch (e) {
        showError();
      }
    }, [showError]);

  const legalDocumentContextValue = useMemo(
    () => ({
      fetchLoanAgreement,
      applicationAgreement,
      fetchApplicationAgreement,
      fetchApplicationAgreementPdf,
      applicationSecci,
      fetchApplicationSecci,
      fetchApplicationSecciPdf,
      fetchAmortizationSchedule,
    }),
    [
      fetchLoanAgreement,
      applicationAgreement,
      fetchApplicationAgreement,
      fetchApplicationAgreementPdf,
      applicationSecci,
      fetchApplicationSecci,
      fetchApplicationSecciPdf,
      fetchAmortizationSchedule,
    ],
  );
  return (
    <LegalDocumentContext.Provider value={legalDocumentContextValue}>
      {children}
    </LegalDocumentContext.Provider>
  );
};

export { useLegalDocuments as default, LegalDocumentProvider };
